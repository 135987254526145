import axios from "axios";
import {gatewayUrl} from "../config/network";
import {IPosition} from "../common/types/gameboard";
import {Address} from "@multiversx/sdk-core/out";
import {numberToPaddedHex} from "@multiversx/sdk-core/out/utils.codec";

type OutputType = "query" | "hex" | "string" | "int";

interface QueryOptions {
    args?: any[];
    value?: string;
    outputType?: OutputType;
}

const SUCCESS_CODE = "successful";

export const querySc = async (
    address: string,
    funcName: string,
    {args, value, outputType}: QueryOptions = {}
) => {
    const computeType = outputType ?? "query";
    const {data: {data, error, code}} = await axios.post(
        `${gatewayUrl}/vm-values/${computeType}`,
        {
            funcName,
            scAddress: address,
            args: args ?? [],
            value: value ?? "0"
        }
    );

    if (code !== SUCCESS_CODE) {
        throw new Error(error);
    }

    return data;

};

export const getMaxStorageByBuilding = async (
    contract: string,
    address: string,
    position: IPosition
) => {
    const {data} = await querySc(
        contract,
        "getMaxStorageByBuilding",
        {
            outputType: "int",
            args: [
                Address.fromBech32(address).hex(),
                numberToPaddedHex(position.x),
                numberToPaddedHex(position.y)
            ],
        }
    );

    return parseInt(data);

};

export const getResourceProductionTimeByBuilding = async (
    contract: string,
    address: string,
    position: IPosition
) => {
    const {data} = await querySc(
        contract,
        "getProductionTimeByBuilding",
        {
            outputType: "int",
            args: [
                Address.fromBech32(address).hex(),
                numberToPaddedHex(position.x),
                numberToPaddedHex(position.y)
            ],
        }
    );

    return parseInt(data);

};

export const getGeneratedRewardsByBuilding = async (
    contract: string,
    address: string,
    position: IPosition
) => {
    const {data} = await querySc(
        contract,
        "getGeneratedRewardsByBuilding",
        {
            outputType: "int",
            args: [
                Address.fromBech32(address).hex(),
                numberToPaddedHex(position.x),
                numberToPaddedHex(position.y)
            ],
        }
    );

    return parseInt(data);

}

export const getUpgradeFinishTime = async (
    contract: string,
    address: string,
    position: IPosition
) => {
    const {data} = await querySc(
        contract,
        "getUpgradeFinishTime",
        {
            outputType: "int",
            args: [
                Address.fromBech32(address).hex(),
                numberToPaddedHex(position.x),
                numberToPaddedHex(position.y)
            ],
        }
    );

    return parseInt(data);
};

export const getNpcLevelByUser = async (address: string, npcId: number) => {
    const {data} = await querySc(
        process.env.NEXT_PUBLIC_QUESTS_CONTRACT!,
        "getNpcLevelByUser",
        {
            outputType: "int",
            args: [
                numberToPaddedHex(npcId),
                Address.fromBech32(address).hex()
            ],
        }
    );

    return parseInt(data);
};

export const getNpcXpByUser = async (address: string, npcId: number) => {
    const {data} = await querySc(
        process.env.NEXT_PUBLIC_QUESTS_CONTRACT!,
        "getNpcXpByUser",
        {
            outputType: "int",
            args: [
                numberToPaddedHex(npcId),
                Address.fromBech32(address).hex()
            ],
        }
    );

    return parseInt(data);
};

export const getIsInventoryWalletAuthorized = async (inventoryAddress: string) => {
    const {data} = await querySc(
        process.env.NEXT_PUBLIC_STAKING_CONTRACT!,
        "isInventoryWalletAuthorized",
        {
            outputType: "int",
            args: [
                Address.fromBech32(inventoryAddress).hex()
            ],
        }
    );

    return parseInt(data) === 1;
}