import BuildChangeRefresh from './BuildChangeRefresh';

export default function Layout({ children }: any) {
  // useBeforeUnload();

  return (
    <>
      <main className="h-[100svh]">{children}</main>

      <footer></footer>

      <BuildChangeRefresh />
    </>
  );
}
