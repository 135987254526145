import {IPosition} from "../../common/types/gameboard";
import {Sprite} from "pixi.js";
import GridAsset from "./GridAsset";
import {AssetStatus, IToken} from "../../common/types/actions";
import Manager from "../Manager";

export default class GridTile extends GridAsset {
    private _isBlankLand: boolean;
    private _selected = false;
    empty: boolean = true;
    isLand: boolean;

    constructor(
        sprite: Sprite,
        position: IPosition,
        token: IToken | null,
        status: AssetStatus = "pending",
        isLand = true,
        isBlankLand = false
    ) {
        super(sprite, position, 1, 1, token, "land", status);
        this.isLand = isLand;
        this._isBlankLand = isBlankLand;
        this.initSprite();
    }

    public computeSpritePosition(offset: IPosition = {x: 0, y: 0}) {
        this.sprite.x = 0.5 * this.screenWidth * (this.position.x - this.position.y) + offset.x;
        this.sprite.y = 0.5 * this.screenHeight * (this.position.x + this.position.y) + offset.y;
    }

    get isBlankLand(): boolean {
        return this._isBlankLand;
    }

    set isBlankLand(value: boolean) {
        this._isBlankLand = value;
    }

    get selected(): boolean {
        return this._selected;
    }

    set selected(value: boolean) {
        this._selected = value;
        if (this._isBlankLand) {return;}
        if (value) {
            this.sprite.tint = 0x6CCDD3;
        } else {
            this.sprite.tint = 0xFFFFFF;
        }
    }

    protected initSprite() {
        this.computeSpritePosition();
        this.sprite.on("mouseover", (e) => {
            this.sprite.alpha = .5;
        }, this.sprite);

        this.sprite.on("mouseout", (e) => {
            this.sprite.alpha = 1;
        }, this.sprite);
    }

    protected initActions() {
        this.sprite.cursor = "pointer";
        this.sprite.on("pointertap", () => {
            Manager.getInstance().publishEvent("LAND_CLICKED", {asset: this});
        });
    }

    public setEmpty(value: boolean = true) {
        this.empty = value;

        return this;
    }

};