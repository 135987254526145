import "../styles/globals.css";

import { DefaultSeo } from "next-seo";
import { Provider } from "react-redux";

import { NetworkEnv } from "../common/types";
import Layout from "../components/Layout";
import { walletConnectProjectId } from "../config";
import { AuthContextProvider } from "../hooks/useAuth";
import store from "../redux/store";

import type { AppProps } from "next/app";
export default function App({ Component, pageProps }: AppProps) {
  return (
    <Provider store={store}>
      <AuthContextProvider env={process.env.NEXT_PUBLIC_NETWORK_ENV as NetworkEnv} projectId={walletConnectProjectId}>
        <Layout>
          <DefaultSeo title="Giants Village" />
          <Component {...pageProps} />
        </Layout>
      </AuthContextProvider>
    </Provider>
  );
}
