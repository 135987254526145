export const gameAction = {
    PLACE_LAND: "placeLand",
    PLACE_BUILDING: "placeBuilding",
    REMOVE_LAND: "removeLand",
    REMOVE_BUILDING: "removeBuilding",
    INIT_UPGRADE: "upgradeBuilding",
    COMPLETE_UPGRADE: "finalizeUpgradeBuilding",
    CLAIM_REWARDS: "claimRewards",
    CLAIM_RESOURCES: "claimResources",
    ADD_GIANTS_TO_HOUSE: "addGiantsToHouse",
    ADD_STAKED_GIANTS_TO_HOUSE: "addGiantsToHouseFromStaking",
    REMOVE_GIANTS_FROM_HOUSE: "removeGiantsFromHouse",
    REMOVE_STAKED_GIANTS_FROM_HOUSE: "removeGiantsFromHouseToStaking",
    ADD_GIANTS_TO_WORK: "addGiantsToWork",
    REMOVE_GIANTS_FROM_WORK: "removeGiantsFromWork",
    ASSETS_TRANSFER: "assetsTransfer",
    COMPLETE_QUEST: "completeQuest",
    UPGRADE_VILLAGE: "upgradeVillage",
    CLAIM_REFERRAL_REWARDS: "claimReferralRewards",
    SET_BUILDING_SKIN: "setBuildingSkin",
    REMOVE_BUILDING_SKIN: "removeBuildingSkin",
    CLAIM_LEADERBOARD_PRIZES: "claimPrize",
    CLAIM_REFERRAL_SPENDINGS: "claimReferralSpendings",
} as const;