import {defaultVertex, IPointData, Polygon} from "pixi.js";
import {IPosition} from "../common/types/gameboard";

export default class AssetShape {
    protected vertices: IPointData[];

    constructor(vertices: IPointData[]) {
        this.vertices = vertices;
    }

    get shape(): Polygon {
        return new Polygon(this.vertices);
    }

    public withOffset(offset: IPosition) {
        const offsetVertices = this.vertices.map(vertex => ({
            x: vertex.x + offset.x,
            y: vertex.y + offset.y
        }));

        return new Polygon(offsetVertices);
    }

};