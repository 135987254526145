import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GiantInventoryAsset, InventoryAsset, InventoryFilters} from "../../common/types/inventory";
import {
    countNfts,
    getAccountNfts,
    getInventoryAssets,
    mapInventoryFilterToCollections,
    mapNftsToInventoryAssets
} from "../../utils/inventory";
import {RootState} from "../store";
import {collections} from "../../config/assets";
import {createSelector} from "reselect";
import {getIdFromInventoryAsset} from "../../utils/nfts";


const GIANTS_PAGE_SIZE = 100;
const initialState: {
    address: string;
    assets: InventoryAsset[];
    status: "idle" | "loading" | "complete" | "error";
    nextPageKey: string;
    assetsToTransfer: { [key: string]: number };
    rewards: InventoryAsset[];
    filters: InventoryFilters;
    landTiles: InventoryAsset[];
    walletGiants: GiantInventoryAsset[];
    walletGiantsStatus: "idle" | "loading" | "complete" | "error";
    walletGiantsNextPageKey: number;
    totalWalletGiants: number;
    totalWalletGiantsStatus: "idle" | "loading" | "complete" | "error";
    skins: InventoryAsset[];
    lockedItems: Record<string, number>;
} = {
    address: "",
    assets: [],
    status: "idle",
    nextPageKey: "",
    assetsToTransfer: {},
    rewards: [],
    filters: {},
    landTiles: [],
    walletGiants: [],
    walletGiantsStatus: "idle",
    walletGiantsNextPageKey: 0,
    totalWalletGiants: 0,
    totalWalletGiantsStatus: "idle",
    skins: [],
    lockedItems: {},
};

export const filterInventoryAssets = createAsyncThunk(
    "inventory/filterAssets",
    (filters: InventoryFilters, {dispatch}) => {
        dispatch(setFilters(filters));
        dispatch(fetchInventoryAssets({reset: true}));
    }
);

export const fetchInventoryAssets = createAsyncThunk(
    "inventory/fetchAssets",
    async (
        {
            address, reset = false
        }: { address?: string, reset?: boolean },
        thunkAPI
    ) => {
        if (address) {
            thunkAPI.dispatch(setAddress(address));
        }
        const {
            inventory: {
                nextPageKey: pageKey,
                address: wallet,
                filters
            }
        } = thunkAPI.getState() as RootState;

        const {assets, nextPageKey} = await getInventoryAssets(
            wallet,
            {reset, pageKey, filters}
        );

        const collections = mapInventoryFilterToCollections(filters.type);
        thunkAPI.dispatch(clearLockedItems(collections));

        return {assets, nextPageKey, reset};
    }
);

export const fetchRewards = createAsyncThunk(
    "inventory/fetchRewards",
    async (
        {address}: { address: string },
        thunkAPI
    ) => {
        const {assets} = await getInventoryAssets(address, {
            reset: true,
            pageKey: "0",
            filters: {
                type: "resources"
            }
        });
        thunkAPI.dispatch(clearLockedItems(collections.rewards));

        return {assets};
    }
);

export const fetchLandTiles = createAsyncThunk(
    "inventory/fetchLandTiles",
    async (_, thunkAPI) => {
        const {
            inventory: {address}
        } = thunkAPI.getState() as RootState;
        if (!address) {
            return {assets: []}
        }
        const {assets} = await getInventoryAssets(
            address,
            {
                filters: {type: "land"},
                pageKey: "0",
                reset: true
            }
        );
        thunkAPI.dispatch(clearLockedItems(collections.land));

        return {assets};
    }
);


export const fetchInventoryGiants = createAsyncThunk(
    "inventory/fetchWalletGiants",
    async (
        {address, reset = false}: { address?: string, reset?: boolean },
        thunkAPI
    ) => {
        if (address) {
            thunkAPI.dispatch(setAddress(address));
        }
        const {
            inventory: {
                address: walletAddress,
                walletGiantsNextPageKey
            }
        } = thunkAPI.getState() as RootState;
        const nfts = await getAccountNfts(walletAddress, {
            params: {
                collections: collections.giants.join(","),
                size: GIANTS_PAGE_SIZE,
                from: reset ? 0 : walletGiantsNextPageKey
            }
        });

        const nextPageKey = nfts.length === GIANTS_PAGE_SIZE
            ? walletGiantsNextPageKey + GIANTS_PAGE_SIZE
            : 0;

        const giants = mapNftsToInventoryAssets(nfts)
            .map(asset => ({...asset, staked: false}));

        return {
            giants,
            nextPageKey,
            reset
        };

    }
);

export const fetchTotalWalletGiants = createAsyncThunk(
    "inventory/fetchTotalWalletGiants",
    async (_, thunkAPI) => {
        const {
            inventory: {
                address: walletAddress
            }
        } = thunkAPI.getState() as RootState;
        const total = await countNfts(walletAddress, collections.giants);

        return {total};
    }
);

export const fetchSkins = createAsyncThunk(
    "inventory/fetchSkins",
    async (_, thunkAPI) => {
        const {
            inventory: {address}
        } = thunkAPI.getState() as RootState;
        const {assets} = await getInventoryAssets(address, {
            reset: true,
            pageKey: "0",
            filters: {
                type: "skins"
            }
        });

        return {assets};
    }
);

const inventorySlice = createSlice({
    initialState,
    name: "inventory",
    reducers: {
        selectAssetToTransfer: (state, action: PayloadAction<{ token: string, qty: number }>) => {
            const {token, qty} = action.payload;
            if (qty > 0) {
                state.assetsToTransfer[token] = qty;
            } else {
                delete state.assetsToTransfer[token];
            }
        },
        clearAssetsToTransfer: (state) => {
            state.assetsToTransfer = {};
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setFilters: (state, action: PayloadAction<InventoryFilters>) => {
            state.filters = action.payload;
        },
        addLockedItems: (state, action: PayloadAction<Record<string, number>>) => {
            for (const key in action.payload) {
                if (state.lockedItems[key]) {
                    state.lockedItems[key] += action.payload[key];
                } else {
                    state.lockedItems[key] = action.payload[key];
                }
            }
        },
        removeLockedItems: (state, action: PayloadAction<Record<string, number>>) => {
            for (const key in action.payload) {
                if (state.lockedItems[key]) {
                    state.lockedItems[key] -= action.payload[key];
                    if (state.lockedItems[key] <= 0) {
                        delete state.lockedItems[key];
                    }
                }
            }
        },
        clearLockedItems: (state, action: PayloadAction<string[]>) => {
            for (const key in state.lockedItems) {
                for (const item of action.payload) {
                    if (key.includes(item)) {
                        delete state.lockedItems[key];
                        break;
                    }
                }
            }

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInventoryAssets.pending, state => {state.status = "loading"})
            .addCase(fetchInventoryAssets.rejected, state => {state.status = "error"})
            .addCase(fetchInventoryAssets.fulfilled, (
                state,
                action: PayloadAction<{
                    assets: InventoryAsset[],
                    nextPageKey: string,
                    reset: boolean
                }>
            ) => {
                const {assets, nextPageKey, reset} = action.payload;
                if (reset) {
                    state.assets = assets;
                } else {
                    state.assets.push(...assets);
                }
                state.nextPageKey = nextPageKey;
                state.status = "complete"
            })
            .addCase(fetchRewards.fulfilled, (
                state,
                action: PayloadAction<{ assets: InventoryAsset[] }>
            ) => {
                state.rewards = action.payload.assets;
            })
            .addCase(fetchLandTiles.fulfilled, (
                state,
                action: PayloadAction<{ assets: InventoryAsset[] }>
            ) => {
                state.landTiles = action.payload.assets;
            })
            .addCase(fetchInventoryGiants.pending, state => {
                state.walletGiantsStatus = "loading";
            })
            .addCase(fetchInventoryGiants.rejected, state => {
                state.walletGiantsStatus = "error";
            })
            .addCase(fetchInventoryGiants.fulfilled, (
                state,
                action: PayloadAction<{
                    giants: GiantInventoryAsset[],
                    nextPageKey: number,
                    reset: boolean
                }>
            ) => {
                state.walletGiantsStatus = "complete";
                state.walletGiantsNextPageKey = action.payload.nextPageKey;
                if (action.payload.reset) {
                    state.walletGiants = action.payload.giants;
                } else {
                    state.walletGiants.push(...action.payload.giants);
                }
            })
            .addCase(fetchSkins.fulfilled, (
                state,
                action: PayloadAction<{ assets: InventoryAsset[] }>
            ) => {
                state.skins = action.payload.assets;
            })
            .addCase(fetchTotalWalletGiants.pending, state => {
                state.totalWalletGiantsStatus = "loading";
            })
            .addCase(fetchTotalWalletGiants.rejected, state => {
                state.totalWalletGiantsStatus = "error";
            })
            .addCase(fetchTotalWalletGiants.fulfilled, (
                state,
                action: PayloadAction<{ total: number }>
            ) => {
                state.totalWalletGiantsStatus = "complete";
                state.totalWalletGiants = action.payload.total;
            });
    },
});

export const {
    selectAssetToTransfer,
    clearAssetsToTransfer,
    setAddress,
    setFilters,
    addLockedItems,
    removeLockedItems,
    clearLockedItems
} = inventorySlice.actions;
export const selectLockedItems = (state: RootState) => state.inventory.lockedItems;
export const hasMoreInventoryAssets = (state: RootState) => state.inventory.nextPageKey !== "";
export const selectHasMoreWalletGiants = (state: RootState) => state.inventory.walletGiantsNextPageKey > 0;
export const selectInventoryAssets = createSelector(
    (state: RootState) => state.inventory.assets,
    selectLockedItems,
    (assets: InventoryAsset[], lockedItems: Record<string, number>) => {
        return assets.reduce((assets: InventoryAsset[], asset) => {
            const lockedQty = lockedItems[getIdFromInventoryAsset(asset)] ?? 0;
            if (asset.type === "NonFungibleESDT" && lockedQty === 0) {
                return [...assets, asset];
            }

            if (asset.quantity - lockedQty > 0) {
                assets.push({
                    ...asset,
                    quantity: asset.quantity - lockedQty
                });
            }

            return assets;
        }, []);
    }
);
export const selectRewardsResources = createSelector(
    (state: RootState) => state.inventory.rewards,
    selectLockedItems,
    (rewards: InventoryAsset[], lockedItems: Record<string, number>) => {
        return rewards.reduce(
            (resources: { [key: string]: number }, resource: InventoryAsset) => {
                const lockedQty = lockedItems[getIdFromInventoryAsset(resource)] ?? 0;
                resources[resource.collection] = resource.quantity - lockedQty;

                return resources;
            },
            {}
        );
    }
);
export const residentsSelector = (state: RootState) => state.inventory.assets.filter(asset => {
    return collections.giants.includes(asset.collection);
});
export const selectInventoryGiants = createSelector(
    (state: RootState) => state.walletAssets.stakedGiants,
    (state: RootState) => state.inventory.walletGiants,
    (state: RootState) => state.village.walletAuthorizedForStaking,
    (stakedGiants, walletGiants, walletAuthorized) => {
        if (!walletAuthorized) {
            return walletGiants;
        }

        return [ ...walletGiants, ...stakedGiants];
    }
);
export const selectTilesQty = createSelector(
    (state: RootState) => state.inventory.landTiles,
    selectLockedItems,
    (landTiles, lockedItems) => {
        if (landTiles.length < 1) {return 0;}

        return landTiles[0].quantity - (lockedItems[landTiles[0].identifier] ?? 0);
    });
export const selectCanRequestAssets = createSelector(
    (state: RootState) => state.inventory.assets,
    (state: RootState) => state.inventory.status,
    (assets, status) => ["idle", "complete"].includes(status) && assets.length < 1
);
export const selectTotalAvailableGiants = createSelector(
    (state: RootState) => state.inventory.totalWalletGiants,
    (state: RootState) => state.walletAssets.stakedGiants,
    (totalWalletGiants: number, stakedGiants: GiantInventoryAsset[]) => {
        return totalWalletGiants + stakedGiants.length;
    });
export default inventorySlice.reducer;