import {AssetData, IBuildingAssetInfo} from '../common/types/gameboard';
import house22 from '../gameboard/assets/house-22.png';
import stonefarm22 from '../gameboard/assets/stone-farm-22.png';
import tileEmpty from '../gameboard/assets/tile-empty.png';
import tile from '../gameboard/assets/tile.png';
import tile0 from '../gameboard/assets/TILE_0.png';
import tile1 from '../gameboard/assets/TILE_1.png';
import tile2 from '../gameboard/assets/TILE_2.png';
import tilePending from '../gameboard/assets/TILE_PENDING.png';
import blankTileAdd0 from '../gameboard/assets/TILE_0_PLUS.png';
import blankTileAdd1 from '../gameboard/assets/TILE_1_PLUS.png';
import blankTileAdd2 from '../gameboard/assets/TILE_2_PLUS.png';
import woodfarm22 from '../gameboard/assets/wood-farm-22.png';
import foodfarm22 from '../gameboard/assets/food-farm-22.png';
import pendingBuilding22 from '../gameboard/assets/pending-building-22.png';
import houseSkin1 from '../gameboard/assets/house-22-skin-1.png';
import houseSkinWinter from '../gameboard/assets/house-22-skin-2.png';
import farmSkinWinter from '../gameboard/assets/food-farm-22-skin-3.png';
import quarrySkinWinter from '../gameboard/assets/stone-farm-22-skin-4.png';
import woodSkinWinter from '../gameboard/assets/wood-farm-22-skin-5.png';

import {
  FOOD_FARM_22,
  GENERAL_BUILDING_22_PENDING,
  HOUSE_22,
  STONE_FARM_22,
  TILE,
  TILE_EMPTY,
  TILE_0,
  TILE_1,
  TILE_2,
  TILE_EMPTY_ADD_0,
  TILE_EMPTY_ADD_1,
  TILE_EMPTY_ADD_2,
  TILE_PENDING,
  WOOD_FARM_22,
  TILE_PATTERN_ID_0,
  TILE_PATTERN_ID_2,
  TILE_PATTERN_ID_1,
} from '../utils/game';
import {getDefaultUpgradeRequirements} from "../utils/assets";
import {CollectionInfo, ShopItem} from "../common/types";
import {AssetAttributes} from "@elrond-giants/game-db/types/entities";

export const CONTRACT_MANAGEMENT = process.env.NEXT_PUBLIC_MANAGEMENT_CONTRACT!;
export const CONTRACT_WOOD_BUILDING = process.env.NEXT_PUBLIC_WOOD_FARM_CONTRACT!;
export const CONTRACT_STONE_BUILDING = process.env.NEXT_PUBLIC_STONE_FARM_CONTRACT!;
export const CONTRACT_FOOD_BUILDING = process.env.NEXT_PUBLIC_FOOD_FARM_CONTRACT!;
export const CONTRACT_HOUSE_BUILDING = process.env.NEXT_PUBLIC_HOUSE_BUILDING_CONTRACT!;

export const TOKEN_BUILDING_WOOD = process.env.NEXT_PUBLIC_WOOD_FARM_COLLECTION!;
export const TOKEN_BUILDING_STONE = process.env.NEXT_PUBLIC_STONE_FARM_COLLECTION!;
export const TOKEN_BUILDING_FOOD = process.env.NEXT_PUBLIC_FOOD_FARM_COLLECTION!;
export const TOKEN_BUILDING_HOUSE = process.env.NEXT_PUBLIC_HOUSE_COLLECTION!;

export const TOKEN_LAND = process.env.NEXT_PUBLIC_LAND_COLLECTION!;
export const TOKEN_WOOD = process.env.NEXT_PUBLIC_WOOD_COLLECTION!;
export const TOKEN_STONE = process.env.NEXT_PUBLIC_STONE_COLLECTION!;
export const TOKEN_FOOD = process.env.NEXT_PUBLIC_FOOD_COLLECTION!;
export const TOKEN_GIANTS = process.env.NEXT_PUBLIC_GIANTS_COLLECTION!;
export const TOKEN_GIANTS_FEMALES = process.env.NEXT_PUBLIC_FEMALE_GIANTS_COLLECTION!;
export const TOKEN_VILLAGE_COIN = process.env.NEXT_PUBLIC_VILLAGE_COIN_COLLECTION!;
export const TOKEN_STAR = process.env.NEXT_PUBLIC_STAR_COLLECTION!;
export const TOKEN_GIANTS_CURRENCY = process.env.NEXT_PUBLIC_GIANTS_CURRENCY_COLLECTION!;
export const TOKEN_STYLE_ESSENCE = process.env.NEXT_PUBLIC_STYLE_ESSENCE_COLLECTION!;
export const TOKEN_SKINS = process.env.NEXT_PUBLIC_SKINS_COLLECTION!;
export const HOUSE_SKIN_NONCE_1 = 1;
export const HOUSE_SKIN_NONCE_2 = 2;
export const FARM_SKIN_NONCE_3 = 3;
export const QUARRY_SKIN_NONCE_4 = 4;
export const LUMBER_SKIN_NONCE_5 = 5;

export const QUEST_TYPE_DELIVER = 0;


export const assets: {
  [key: string]: {
    [key: string | number]: AssetData
  }
} = {
  [TOKEN_LAND]: {
    1: {
      title: "Land",
      description: "Place land to extend your village and place more buildings.",
      type: "land",
      attributes: {
        height: 1,
        width: 1,
      },
      thumbnail: "/thumbnails/land.webp",
      sources: {
        complete: {
          src: tile.src,
          alias: [TILE],
        },
        pending: {
          src: tilePending.src,
          alias: [TILE_PENDING],
        },
        upgrading: {
          src: tile.src,
          alias: [TILE],
        },
        "finalizing-upgrade": {
          src: tile.src,
          alias: [TILE],
        },
      }
    },
  },
  [TILE_EMPTY]: {
    1: {
      title: "Land Empty",
      description: "Place land to extend your village and place more buildings.",
      type: "land",
      attributes: {
        height: 1,
        width: 1,
      },
      thumbnail: "/thumbnails/land.webp",
      sources: {
        complete: {
          src: tileEmpty.src,
          alias: [TILE_EMPTY],
        },
        pending: {
          src: tileEmpty.src,
          alias: [TILE_EMPTY],
        },
        upgrading: {
          src: tileEmpty.src,
          alias: [TILE_EMPTY],
        },
        "finalizing-upgrade": {
          src: tileEmpty.src,
          alias: [TILE_EMPTY],
        }
      },
    }
  },
  [TOKEN_BUILDING_HOUSE]: {
    1: {
      title: "Giants House",
      description: "The house of your Giants. More houses means more Giants into the village.",
      type: "building",
      attributes: {
        height: 2,
        width: 2,
      },
      thumbnail: "/thumbnails/house.webp",
      sources: {
        complete: {
          src: house22.src,
          alias: [HOUSE_22],
        },
        pending: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        upgrading: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        "finalizing-upgrade": {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        }
      },
    }
  },
  [TOKEN_BUILDING_WOOD]: {
    1: {
      title: "Lumberjack's C.",
      description: "In the Lumberjack's Cottage you can chop wood.",
      type: "building",
      attributes: {
        height: 2,
        width: 2,
      },
      thumbnail: "/thumbnails/wood.webp",
      sources: {
        complete: {
          src: woodfarm22.src,
          alias: [WOOD_FARM_22],
        },
        pending: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        upgrading: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        "finalizing-upgrade": {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        }
      },
    }
  },
  [TOKEN_BUILDING_STONE]: {
    1: {
      title: "The Quarry",
      description: "In The Quarry you can mine stone.",
      type: "building",
      attributes: {
        height: 2,
        width: 2,
      },
      thumbnail: "/thumbnails/stone.webp",
      sources: {
        complete: {
          src: stonefarm22.src,
          alias: [STONE_FARM_22],
        },
        pending: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        upgrading: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        "finalizing-upgrade": {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        }
      },
    }
  },
  [TOKEN_BUILDING_FOOD]: {
    1: {
      title: "The Farm",
      description: "In The Farm you can grow wheat.",
      type: "building",
      attributes: {
        height: 2,
        width: 2,
      },
      thumbnail: "/thumbnails/food.webp",
      sources: {
        complete: {
          src: foodfarm22.src,
          alias: [FOOD_FARM_22],
        },
        pending: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        upgrading: {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        },
        "finalizing-upgrade": {
          src: pendingBuilding22.src,
          alias: [GENERAL_BUILDING_22_PENDING],
        }
      },
    }
  },
};

export const landPatternSources: {
  [key: string | number]: {
    src: string;
    alias: string[];
  };
} = {
  [TILE_PATTERN_ID_0]: {
    src: tile0.src,
    alias: [TILE_0],
  },
  [TILE_PATTERN_ID_1]: {
    src: tile1.src,
    alias: [TILE_1],
  },
  [TILE_PATTERN_ID_2]: {
    src: tile2.src,
    alias: [TILE_2],
  }
};

export const blankLandPatternSources = {
  [TILE_PATTERN_ID_0]: {
    src: blankTileAdd0.src,
    alias: [TILE_EMPTY_ADD_0],
  },
  [TILE_PATTERN_ID_1]: {
    src: blankTileAdd1.src,
    alias: [TILE_EMPTY_ADD_1],
  },
  [TILE_PATTERN_ID_2]: {
    src: blankTileAdd2.src,
    alias: [TILE_EMPTY_ADD_2],
  }
};

export const skinSources: {
  [key: string]: {
    [key: string | number]: {
      src: string;
      alias: string[];
    };
  };
} = {
  [TOKEN_SKINS]: {
    [HOUSE_SKIN_NONCE_1]: {
      src: houseSkin1.src,
      alias: [TOKEN_SKINS + "-" + HOUSE_SKIN_NONCE_1],
    },
    [HOUSE_SKIN_NONCE_2]: {
      src: houseSkinWinter.src,
      alias: [TOKEN_SKINS + "-" + HOUSE_SKIN_NONCE_2],
    },
    [FARM_SKIN_NONCE_3]: {
      src: farmSkinWinter.src,
      alias: [TOKEN_SKINS + "-" + FARM_SKIN_NONCE_3],
    },
    [QUARRY_SKIN_NONCE_4]: {
      src: quarrySkinWinter.src,
      alias: [TOKEN_SKINS + "-" + QUARRY_SKIN_NONCE_4],
    },
    [LUMBER_SKIN_NONCE_5]: {
      src: woodSkinWinter.src,
      alias: [TOKEN_SKINS + "-" + LUMBER_SKIN_NONCE_5],
    },
  }
}

export const blankTile = {
  src: tileEmpty.src,
  alias: [TILE_EMPTY],
};

export const landPatternMatrix = [
  [1, 0, 2, 2, 1, 1, 0, 0, 0, 1, 0, 0, 1, 1, 0],
  [1, 0, 2, 0, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0, 1],
  [1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 1, 0, 1],
  [1, 0, 0, 0, 0, 1, 0, 1, 1, 1, 0, 0, 0, 0, 0],
  [2, 2, 0, 0, 2, 0, 0, 0, 1, 1, 0, 0, 0, 2, 2],
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0, 1, 2, 2, 2, 0, 0, 0, 0],
  [1, 0, 2, 2, 2, 0, 0, 1, 2, 2, 2, 0, 0, 0, 0],
  [1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 0, 1, 1, 1, 1],
  [1, 1, 1, 1, 1, 2, 2, 1, 1, 0, 2, 0, 0, 0, 0],
  [0, 1, 1, 1, 1, 2, 2, 0, 0, 0, 0, 2, 2, 0, 0],
  [2, 2, 2, 1, 1, 1, 0, 0, 0, 0, 0, 2, 2, 2, 2],
  [0, 2, 2, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
  [1, 1, 2, 0, 0, 1, 0, 0, 0, 1, 0, 0, 1, 1, 1],
];

export const buildings: { [key: string]: IBuildingAssetInfo } = {
  [TOKEN_BUILDING_WOOD]: {
    contract: CONTRACT_WOOD_BUILDING,
    rewardsToken: TOKEN_WOOD,
    maxLevel: 20,
    unlockOnVillageLevel: 0,
    getUpgradeRequirements: ({currentLevel}) => getDefaultUpgradeRequirements(currentLevel),
    getInstantUpgradeTaxPercentage: ({currentLevel}) => 0.3,
    getGiantsCapacity: ({currentLevel}) => 3,
    skins: [
      {
        token: {
          id: TOKEN_SKINS,
          nonce: LUMBER_SKIN_NONCE_5
        },
        thumbnail: "/thumbnails/lumber-skin-winter.webp"
      }
    ]
  },
  [TOKEN_BUILDING_STONE]: {
    contract: CONTRACT_STONE_BUILDING,
    rewardsToken: TOKEN_STONE,
    maxLevel: 20,
    unlockOnVillageLevel: 3,
    getUpgradeRequirements: ({currentLevel}) => getDefaultUpgradeRequirements(currentLevel),
    getInstantUpgradeTaxPercentage: ({currentLevel}) => 0.3,
    getGiantsCapacity: ({currentLevel}) => 3,
    skins: [
      {
        token: {
          id: TOKEN_SKINS,
          nonce: QUARRY_SKIN_NONCE_4
        },
        thumbnail: "/thumbnails/quarry-skin-winter.webp"
      }
    ]
  },
  [TOKEN_BUILDING_FOOD]: {
    contract: CONTRACT_FOOD_BUILDING,
    rewardsToken: TOKEN_FOOD,
    maxLevel: 20,
    unlockOnVillageLevel: 1,
    getUpgradeRequirements: ({currentLevel}) => getDefaultUpgradeRequirements(currentLevel),
    getInstantUpgradeTaxPercentage: ({currentLevel}) => 0.3,
    getGiantsCapacity: ({currentLevel}) => 3,
    skins: [
      {
        token: {
          id: TOKEN_SKINS,
          nonce: FARM_SKIN_NONCE_3
        },
        thumbnail: "/thumbnails/food-farm-skin-winter.webp"
      }
    ]
  },
  [TOKEN_BUILDING_HOUSE]: {
    contract: CONTRACT_HOUSE_BUILDING,
    rewardsToken: null,
    maxLevel: 5,
    unlockOnVillageLevel: 0,
    getUpgradeRequirements: ({currentLevel}) => getDefaultUpgradeRequirements(currentLevel),
    getInstantUpgradeTaxPercentage: ({currentLevel}) => 0.3,
    getGiantsCapacity: ({currentLevel}) => currentLevel,
    skins: [
      {
        token: {
          id: TOKEN_SKINS,
          nonce: HOUSE_SKIN_NONCE_1
        },
        thumbnail: "/thumbnails/house-skin-1.webp"
      },
      {
        token: {
          id: TOKEN_SKINS,
          nonce: HOUSE_SKIN_NONCE_2
        },
        thumbnail: "/thumbnails/house-skin-winter.webp"
      }
    ]
  },
};

export const collections = {
  buildings: [TOKEN_BUILDING_HOUSE, TOKEN_BUILDING_STONE, TOKEN_BUILDING_FOOD, TOKEN_BUILDING_WOOD],
  land: [TOKEN_LAND],
  rewards: [TOKEN_WOOD, TOKEN_STONE, TOKEN_FOOD, TOKEN_VILLAGE_COIN, TOKEN_STAR],
  giants: [TOKEN_GIANTS, TOKEN_GIANTS_FEMALES],
  skins: [TOKEN_SKINS],
  farms: [TOKEN_BUILDING_WOOD, TOKEN_BUILDING_STONE, TOKEN_BUILDING_FOOD],
};

export const shopItems: ShopItem[] = [
  {
    token: TOKEN_BUILDING_HOUSE,
    nonce: 1,
    title: "Giants House",
    type: "NonFungibleESDT",
    price: 4000,
    thumbnail: "/thumbnails/house.webp",
  },
  {
    token: TOKEN_BUILDING_WOOD,
    nonce: 1,
    title: "Lumberjack's Cottage",
    type: "NonFungibleESDT",
    price: 3840,
    thumbnail: "/thumbnails/wood.webp",
  },
  {
    token: TOKEN_BUILDING_STONE,
    nonce: 1,
    title: "The Quarry",
    type: "NonFungibleESDT",
    price: 3840,
    thumbnail: "/thumbnails/stone.webp",
  },
  {
    token: TOKEN_BUILDING_FOOD,
    nonce: 1,
    title: "The Farm",
    type: "NonFungibleESDT",
    price: 3840,
    thumbnail: "/thumbnails/food.webp"
  },
  {
    token: TOKEN_LAND,
    nonce: 1,
    title: "Land",
    type: "SemiFungibleESDT",
    price: 400,
    thumbnail: "/thumbnails/land.webp"
  },
  {
    token: TOKEN_SKINS,
    nonce: LUMBER_SKIN_NONCE_5,
    title: "Lumberjack's C. Skin",
    type: "SemiFungibleESDT",
    price: 4800,
    thumbnail: "/thumbnails/lumber-skin-winter.webp"
  },
  {
    token: TOKEN_SKINS,
    nonce: QUARRY_SKIN_NONCE_4,
    title: "Quarry Skin",
    type: "SemiFungibleESDT",
    price: 4800,
    thumbnail: "/thumbnails/quarry-skin-winter.webp"
  },
  {
    token: TOKEN_SKINS,
    nonce: FARM_SKIN_NONCE_3,
    title: "Farm Skin",
    type: "SemiFungibleESDT",
    price: 4800,
    thumbnail: "/thumbnails/food-farm-skin-winter.webp"
  }
];

export const defaultAssetAttributes: AssetAttributes = {
  width: {value: 1, status: "complete"},
  height: {value: 1, status: "complete"},
  level: {value: 1, status: "complete"}
};

export const collectionItemsInfo: {
  buildings: CollectionInfo[],
  resources: CollectionInfo[],
} = {
  buildings: [
    {
      title: "Giants House",
      collection: TOKEN_BUILDING_HOUSE,
      thumbnail: "/thumbnails/house.webp",
    },
    {
      title: "Wood Farm",
      collection: TOKEN_BUILDING_WOOD,
      thumbnail: "/thumbnails/wood.webp",
    },
    {
      title: "Stone Farm",
      collection: TOKEN_BUILDING_STONE,
      thumbnail: "/thumbnails/stone.webp",
    },
    {
      title: "Food Farm",
      collection: TOKEN_BUILDING_FOOD,
      thumbnail: "/thumbnails/food.webp",
    },
    {
      title: "Land",
      collection: TOKEN_LAND,
      thumbnail: "/thumbnails/land.webp",
    },
  ],
  resources: [
    {
      title: "Wood",
      collection: TOKEN_WOOD,
    },
    {
      title: "Stone",
      collection: TOKEN_STONE,
    },
    {
      title: "Wheat",
      collection: TOKEN_FOOD,
    },
    {
      title: "Villager Coin",
      collection: TOKEN_VILLAGE_COIN,
    },
    {
      title: "Style Essence",
      collection: TOKEN_STYLE_ESSENCE,
      thumbnail: "/thumbnails/funky-essence.png",
    },
  ],
}