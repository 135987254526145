import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ShopItem} from "../../common/types";
import {shopItems} from "../../config/assets";
import {RootState} from "../store";

const initialState: {
    items: ShopItem[];
    itemsToBuy: { [key: string]: number };
} = {
    items: shopItems,
    itemsToBuy: {}
}

const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        setItemToBuy: (state, action: PayloadAction<{ token: string, nonce: number, qty: number }>) => {
            const {token, nonce, qty} = action.payload;
            const id = `${token}-${nonce}`;
            if (qty > 0) {
                state.itemsToBuy[id] = qty;
            } else {
                delete state.itemsToBuy[id];
            }
        },
        resetItemsToBuy: (state) => {
            state.itemsToBuy = {};
        }
    }
});
export const selectShopItems = (state: RootState) => state.shop.items;
export const selectItemsToBuy = (state: RootState) => state.shop.itemsToBuy;
export const {setItemToBuy, resetItemsToBuy} = shopSlice.actions;
export default shopSlice.reducer;