import {BinaryCodec} from "@multiversx/sdk-core/out";
import {StructBinaryCodec} from "@multiversx/sdk-core/out/smartcontracts/codec/struct";
import {buildingAttributesType} from "./customTypes";
import {AssetAttributes, BuildingType} from "@elrond-giants/game-db/types/entities";
import {WithRequired} from "../common/types";

export const decodeBuildingAttributes = (attributes: string): WithRequired<AssetAttributes, "type"> => {
    const codec = new StructBinaryCodec(new BinaryCodec());
    const buf = Buffer.from(attributes, "base64");

    const buildingAttributes = codec.decodeTopLevel(buf, buildingAttributesType);

    const skin = buildingAttributes.getFieldValue("skin");
    let skinValue = null;
    if (skin) {
        skinValue = {
            id: skin.token_identifier,
            nonce: skin.token_nonce.toNumber(),
            quantity: skin.amount.toNumber()
        }
    }

    return {
        width: {
            value: buildingAttributes.getFieldValue("width").toNumber(),
            status: "complete"
        },
        height: {
            value: buildingAttributes.getFieldValue("height").toNumber(),
            status: "complete"
        },
        level: {
            value: buildingAttributes.getFieldValue("level").toNumber(),
            status: "complete"
        },
        type: {
            value: BuildingType[
                buildingAttributes
                    .getFieldValue("building_type")
                    .name as keyof typeof BuildingType
            ],
            status: "complete"
        },
        skin: {
            value: skinValue,
            status: "complete"
        }
    };

};