import {IGridAsset, IPosition} from "../common/types/gameboard";
import {IPointData} from "pixi.js";
import GridTile from "../gameboard/components/GridTile";
import GridAsset from "../gameboard/components/GridAsset";
import {
    buildings,
    TOKEN_BUILDING_FOOD,
    TOKEN_BUILDING_HOUSE,
    TOKEN_BUILDING_STONE,
    TOKEN_BUILDING_WOOD, TOKEN_FOOD, TOKEN_STONE, TOKEN_WOOD
} from "../config/assets";
import {MAX_UPGRADING_BUILDINGS_MULTIPLIER} from "../config/misc";

export const TILE = "tile-isometric";
export const TILE_EMPTY = "tile-isometric-empty";
export const TILE_0 = "tile-isometric-0";
export const TILE_1 = "tile-isometric-1";
export const TILE_2 = "tile-isometric-2";
export const TILE_PENDING = "tile-pending";
export const TILE_EMPTY_ADD_0 = "tile-isometric-empty-add-0";
export const TILE_EMPTY_ADD_1 = "tile-isometric-empty-add-1";
export const TILE_EMPTY_ADD_2 = "tile-isometric-empty-add-2";
export const HOUSE_22 = "house22";
export const GENERAL_BUILDING_22_PENDING = "house22pending";
export const HOUSE_22_PENDING = "house22pending";
export const WOOD_FARM_22 = "woodfarm22";
export const WOOD_FARM_22_PENDING = "woodfarm22pending";
export const STONE_FARM_22 = "stonefarm22";
export const STONE_FARM_22_PENDING = "stonefarm22pending";
export const FOOD_FARM_22 = "foodfarm22";
export const FOOD_FARM_22_PENDING = "foodfarm22pending";
export const TILE_PATTERN_ID_0 = 0;
export const TILE_PATTERN_ID_1 = 1;
export const TILE_PATTERN_ID_2 = 2;


export const TILE_WIDTH = 121;
export const TILE_HEIGHT = 70;



export const clamp = (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max)
};

export const isSamePosition = (p1: IPosition | IPointData, p2: IPosition | IPointData): boolean => {
    return p1.x === p2.x && p1.y === p2.y;
}

export const compareGridPositions = (p1: IPosition, p2: IPosition): -1 | 0 | 1 => {
    // p1 is on a row lower than p2
    if (p1.y < p2.y) {
        return -1;
    }
    // p1 is on a row higher than p2
    if (p1.y > p2.y) {
        return 1;
    }

    // p1 and p2 are on the same row, so we'll
    // compare column positions

    if (p1.x === p2.x) {
        return 0;
    }

    return p1.x < p2.x ? -1 : 1;
};

export const compareAssetsGridPositions = (a: IGridAsset, b: IGridAsset) => {
    let sum = 0;
    for (let aPosition of a.allPositions) {
        for (let bPosition of b.allPositions) {
            sum += compareGridPositions(aPosition, bPosition);
        }
    }

    return sum;

};

export const validNeighbourTile = (tile: GridTile | null) => tile && (
    (!tile.isBlankLand && !tile.selected) || (tile.isBlankLand && tile.selected)
);

export const computeAssetScreenPosition = (asset: GridAsset, {x, y}: IPosition, scale: number = 1): IPosition => {
    const res =  {
        x: x - TILE_WIDTH * scale  / 2,
        y: y - asset.sprite.height * scale + asset.screenHeight * scale
    };

    return res;
}

export const npcLevelUpXp = (currentLevel: number): number => {
    if (currentLevel <= 4) {
        return 5;
    } else if (currentLevel <= 9) {
        return 8;
    } else if (currentLevel <= 14) {
        return 11;
    } else if (currentLevel <= 19) {
        return 17;
    }

    return 25;
}

export const starsForLevel = (level: number): number => {
    if (level < 10) {return 1;}

    return 2;
}

export const starsRequiredForLevelUp = (level: number): number => {
    if (level <= 4) {return 5;}
    if (level <= 9) {return 8;}
    if (level <= 14) {return 11;}
    if (level <= 19) {return 17;}

    return 25;
}

/**
 * Return the village level requirement for a building
 * @param buildingToken
 * @param buildingLevel
 *
 * @returns {number} -1 if the building level is higher than the max level of the building.
 * @returns {number} n(>-1) for the village level requirement
 */
export const getVillageLevelRequirementForBuilding = (
    buildingToken: string,
    buildingLevel: number
): number => {
    const step = 5;
    const buildingData = buildings[buildingToken];
    if (!buildingData || buildingData.maxLevel < buildingLevel) {return -1;}
    if (buildingLevel > 95) {return 20;}

    if (buildingToken === TOKEN_BUILDING_HOUSE) {
        return 0;
    }
    if (buildingToken === TOKEN_BUILDING_WOOD && buildingLevel < 6) {
        return 0;
    }
    if (buildingToken === TOKEN_BUILDING_FOOD && buildingLevel < 10) {
        return 1;
    }
    if (buildingToken === TOKEN_BUILDING_STONE && buildingLevel < 30) {
        return 3;
    }

    return Math.ceil(buildingLevel / step);

};

export const getMaxLandQtyForVillageLevel = (villageLevel: number): number => {
    return 20 + villageLevel * 12;
};

export const computeMaxLandQty = (
    villageLevel: number,
    placedLandQty: number,
    availableLandQty?: number
): number => {
    const maxLandQtyForLevel = getMaxLandQtyForVillageLevel(villageLevel);
    const maxQty = Math.max(0, maxLandQtyForLevel - placedLandQty);
    if (availableLandQty !== undefined) {
        return Math.min(availableLandQty, maxQty);
    }

    return maxQty;
}

export const getMaxUpgradingBuildingsQty = (placedBuildingsQty: number): number => {
    return Math.round(placedBuildingsQty * MAX_UPGRADING_BUILDINGS_MULTIPLIER);
}

export const getResourceName = (tokenIdentifier: string): string => {
    switch (tokenIdentifier) {
        case TOKEN_WOOD:
            return "Wood";
        case TOKEN_STONE:
            return "Stone";
        case TOKEN_FOOD:
            return "Food";
        default:
            return "";
    }
}