import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FarmHarvestStatus} from "../../common/types/resources";
import {ResourceClaim} from "@elrond-giants/game-db/types/entities";

type StateType = {
    harvestingState: FarmHarvestStatus[];
    status: "idle" | "loading" | "complete" | "error";
    claim: Required<ResourceClaim> | null;
    claimFetchStatus: "idle" | "loading" | "complete" | "error";
};

const initialState: StateType = {
    harvestingState: [],
    status: "idle",
    claim: null,
    claimFetchStatus: "idle",
};

export const fetchHarvestingState = createAsyncThunk(
    "resources/getResidualClaims",
    async ({fetch}: { fetch: () => Promise<FarmHarvestStatus[]> }) => {
        return await fetch();
    }
);

export const fetchLatestClaim = createAsyncThunk(
    "resources/getClaim",
    async ({fetch}: { fetch: () => Promise<Required<ResourceClaim> | null> }) => {
        return await fetch();
    }
);

const slice = createSlice({
    initialState,
    name: "resources",
    reducers: {
        setClaim: (state, action: PayloadAction<Required<ResourceClaim> | null>) => {
            state.claim = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHarvestingState.pending, state => {state.status = "loading"})
            .addCase(fetchHarvestingState.rejected, state => {state.status = "error"})
            .addCase(fetchHarvestingState.fulfilled, (state, action) => {
                state.status = "complete";
                state.harvestingState = action.payload;
            })
            .addCase(fetchLatestClaim.pending, state => {state.claimFetchStatus = "loading"})
            .addCase(fetchLatestClaim.rejected, state => {state.claimFetchStatus = "error"})
            .addCase(fetchLatestClaim.fulfilled, (state, action) => {
                state.claimFetchStatus = "complete";
                state.claim = action.payload;
            });
    }
});
export const {setClaim} = slice.actions;
export default slice.reducer;