import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PopupEnum, PopupState} from "../../common/types/components";
import {RootState} from "../store";


const initialState: {
    items: PopupState[];
    active: PopupState | null;
} = {
    items: [],
    active: null,
}
const slice = createSlice({
    name: "popupManagement",
    initialState,
    reducers: {
        showPopup(state, action: PayloadAction<PopupState>) {
            // If the popup is idle, remove it from the list
            const idx = state.items.findIndex(item => item.type === action.payload.type);
            if (idx !== -1) {
                state.items.splice(idx, 1);
            }

            if (state.active && !state.active.removeWhenInactive) {
                state.items.push(state.active);
            }

            state.active = action.payload;
        },
        closePopup(state, action: PayloadAction<PopupEnum>) {
            if (state.active?.type === action.payload) {
                state.active = state.items.pop() || null;
            }

            const idx = state.items.findIndex(item => item.type === action.payload);
            if (idx !== -1) {
                state.items.splice(idx, 1);
            }
        },
    }
});
export const {showPopup, closePopup} = slice.actions;
export default slice.reducer;