import { AssetAttributes, VillageAsset } from "@elrond-giants/game-db/types/entities";
import { Assets, Sprite } from "pixi.js";

import { SkinItem } from "../common/types";
import {IToken, TokenPayload} from "../common/types/actions";
import { AssetData, IBuildingAssetInfo, IPosition } from "../common/types/gameboard";
import {
  assets,
  blankLandPatternSources, buildings,
  collections,
  landPatternMatrix,
  landPatternSources,
  skinSources,
  TOKEN_BUILDING_HOUSE,
  TOKEN_GIANTS_CURRENCY,
  TOKEN_LAND,
  TOKEN_VILLAGE_COIN,
} from "../config/assets";
import GridAsset from "../gameboard/components/GridAsset";
import GridTile from "../gameboard/components/GridTile";
import GridAssetsList from "../gameboard/GridAssetsList";
import { TILE_EMPTY } from "./game";
import {PartialVillageAsset} from "../common/types/repository";

const getLandPatternType = (_x: number, _y: number): number => {
  const x = _x % landPatternMatrix.length;
  const y = _y % landPatternMatrix.length;

  return landPatternMatrix[y][x];
};

export const getAssetDetails = (tokenId: string, tokenNonce: number): AssetData => {
  return assets[tokenId][tokenNonce] ?? assets[tokenId][1];
};

export const getAssetSize = (tokenId: string, tokenNonce: number) => {
  const asset = getAssetDetails(tokenId, tokenNonce);

  return {
    width: asset.attributes.width,
    height: asset.attributes.height,
  };
};

export const gridAssetFromAssetInfo = (
    asset: Omit<PartialVillageAsset, "type">
): GridAsset | GridTile => {
  const assetData = getAssetDetails(asset.asset_token, asset.asset_token_nonce);
  const source = getAssetSrc(asset);
  const sprite = Sprite.from(source);
  const token: IToken = { id: asset.asset_token, nonce: asset.asset_token_nonce };
  if (assetData.type === "land") {
    return new GridTile(sprite, asset.position, token, asset.status).setInteractive(false);
  }

  const upgradeState = {
    startDate: asset.upgrade_start_date,
    finishDate: asset.upgrade_finish_date,
    payment: asset.upgrade_payment as Required<TokenPayload>,
    instantFinishDate: asset.upgrade_instant_finish_date,
    instantFinishTaxPayment: asset.upgrade_instant_finish_tax_payment as Required<TokenPayload>,
  }

  return new GridAsset(
      sprite,
      asset.position,
      assetData.attributes.width,
      assetData.attributes.height,
      token,
      assetData.type,
      asset.status,
      asset.attributes as AssetAttributes,
      upgradeState
  );
};

export const getGridRows = (tiles: GridAssetsList<GridTile>) => {
  return tiles.reduce((rows: { [key: number]: { tiles: GridTile[]; lastColumn: number } }, tile: GridTile) => {
    if (!rows[tile.position.y]) {
      rows[tile.position.y] = { tiles: [], lastColumn: 0 };
    }

    rows[tile.position.y].tiles.push(tile);
    rows[tile.position.y].lastColumn = Math.max(rows[tile.position.y].lastColumn, tile.position.x);

    return rows;
  }, {});
};

export const getGridCols = (tiles: GridAssetsList<GridTile>) => {
  return tiles.reduce((cols: { [key: number]: { tiles: GridTile[]; lastRow: number } }, tile: GridTile) => {
    if (!cols[tile.position.x]) {
      cols[tile.position.x] = { tiles: [], lastRow: 0 };
    }

    cols[tile.position.x].tiles.push(tile);
    cols[tile.position.x].lastRow = Math.max(cols[tile.position.x].lastRow, tile.position.y);

    return cols;
  }, {});
};

export const isBlankLand = (tile: GridTile) => tile.isBlankLand;

export const createBlankLand = (position: IPosition): GridTile => {
  const patternType = getLandPatternType(position.x, position.y);
  // @ts-ignore
  const srcData = blankLandPatternSources[patternType];
  const source = Assets.get(srcData.alias[0]);
  const sprite = Sprite.from(source);

  return new GridTile(
    sprite,
    { ...position },
    {
      id: TILE_EMPTY,
      nonce: 1,
    },
    "complete",
    true,
    true
  );
};

export const isHouse = (tokenId: string) => tokenId === TOKEN_BUILDING_HOUSE;

export const getUpgradeTime = (currentLevel: number): number => {
  const nextLevel = currentLevel + 1;
  if (nextLevel >= 20) {
    return 126_000;
  }

  return 26_250 + (nextLevel - 1) * 5_250;
};

export const getFarmStorage = (currentLevel: number): number => {
  if (currentLevel <= 4) {
    return 25;
  } else if (currentLevel <= 9) {
    return 100;
  } else if (currentLevel <= 14) {
    return 225;
  } else if (currentLevel <= 19) {
    return 400;
  }

  return 625;
};

export const getFarmBaseProductionSpeed = (currentLevel: number): number => {
  return 160 - currentLevel * 3;
};

export const getFarmBaseProductionImprovement = (currentLevel: number): string => {
  const currentBaseProduction = getFarmBaseProductionSpeed(currentLevel);
  const nextBaseProduction = getFarmBaseProductionSpeed(currentLevel + 1);

  const delta = currentBaseProduction - nextBaseProduction;

  return ((100 * delta) / currentBaseProduction).toFixed(1);
};

export const getDefaultUpgradeRequirements = (currentLevel: number): Map<string, number> => {
  const nextLevel = currentLevel + 1;
  const giantTokenAmount = 100 + nextLevel * 80;
  const villageCoinAmount = (100 + nextLevel * 25) * 15 * (1 + Math.floor(nextLevel / 4));

  return new Map().set(TOKEN_VILLAGE_COIN, villageCoinAmount).set(TOKEN_GIANTS_CURRENCY, giantTokenAmount);
};

export const getSkinItem = (buildingInfo: IBuildingAssetInfo, skinToken: IToken): SkinItem | null => {
  const skinInfo = buildingInfo.skins.find((skin) => skin.token.nonce === skinToken.nonce);
  if (!skinInfo) {
    return null;
  }

  return {
    token: skinToken,
    thumbnail: skinInfo.thumbnail,
  };
};

export const getAssetSrc = (
  asset: Pick<VillageAsset, "asset_token" | "asset_token_nonce" | "status" | "position" | "attributes">
): string => {
  const assetData = getAssetDetails(asset.asset_token, asset.asset_token_nonce);
  if (asset.asset_token === TOKEN_LAND && asset.status === "complete") {
    const patternType = getLandPatternType(asset.position.x, asset.position.y);
    const srcData = landPatternSources[patternType];

    return srcData.src;
  }

  if (
    collections.buildings.includes(asset.asset_token) &&
    asset.status === "complete" &&
    asset.attributes.skin?.value
  ) {
    const skinSrc = skinSources[asset.attributes.skin.value.id]
      ? skinSources[asset.attributes.skin.value.id][asset.attributes.skin.value.nonce] ?? null
      : null;
    if (skinSrc) {
      return skinSrc.alias[0];
    }
  }

  return assetData.sources[asset.status].alias[0];
};

export const getFarmAssetInfoByRewardToken = (token: IToken) => {
    const farmInfo = Object.values(buildings).find((farm) => farm.rewardsToken === token.id);
    if (!farmInfo) {
      throw new Error(`There is no farm with reward token ${token.id}`);
    }

    return farmInfo;
}
