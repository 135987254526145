import axios from 'axios';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import ActionButton from './shared/ActionButton';
import Popup from './shared/Popup';

const fetcher = (url: string) => axios.get(url).then((res) => res?.data?.buildId);

export default function BuildChangeRefresh() {
  const { data, error } = useSWR("/api/build-id", fetcher);
  const [currentBuildId, setCurrentBuildId] = useState();
  const [reloadPopupOpen, setReloadPopupOpen] = useState(false);

  useEffect(() => {
    if (currentBuildId && currentBuildId !== data) {
      setReloadPopupOpen(true);
    }
    if (!currentBuildId) {
      setCurrentBuildId(data);
    }
  }, [data]);

  return (
    <Popup open={reloadPopupOpen} setOpen={() => setReloadPopupOpen(false)} widthClass="w-full md:max-w-[26rem]">
      <div className="flex flex-col items-start p-2">
        <div className="flex items-center space-x-4">
          <div className="flex flex-col items-start space-y-1">
            <h2 className="text-xl text-theme-text font-semibold">Update available</h2>
          </div>
        </div>
        <p className="text-theme-text-light mt-2 text-left">
          A new game update is available. Please reload the page to get the latest version and avoid any issues.
        </p>
        <div className="flex flex-col flex-grow justify-end w-full">
          <div className="flex flex-col md:flex-row items-center justify-center gap-3 w-full mt-2">
            <ActionButton onClick={() => window.location.reload()}>Reload</ActionButton>
            <ActionButton onClick={() => setReloadPopupOpen(false)} style={"secondary"}>
              cancel
            </ActionButton>
          </div>
        </div>
      </div>
    </Popup>
  );
}
