import axios, {AxiosRequestConfig} from "axios";
import {useAuth} from "./useAuth";

export const useApi = () => {
    const {token} = useAuth();
    const api = axios.create({
        baseURL: "/api/",
    });
    api.interceptors.request.use(async (config) => {
     
        if (token) {
            config.headers!.Authorization = `Bearer ${token}`;
        }
        return config;
    });
    // handle 503 errors
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            if (error.response?.status === 503){
                window.location.href = "/auth";
            }
            return Promise.reject(error);
        }
    );

    return {api};
}