import {createContext, PropsWithChildren, useContext, useEffect, useMemo, useState} from "react";
import {NetworkEnv} from "@elrond-giants/erdjs-auth/dist/types";
import {Account, Village} from "@elrond-giants/game-db/types/entities";
import Wallet from "../auth/Wallet";
import MainWallet from "../auth/MainWallet";
import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import {network} from "../config";
import {network as configNetwork} from "../config/network";
import ProviderBuilder from "../auth/ProviderBuilder";
import AccountBalance from "../auth/AccountBalance";
import {useApi} from "./useApi";
import {villageInfoPath} from "../utils/api-routes";
import {VillageInfoResponse} from "../common/types/responses";

interface IAuthContext {
    account: Account | null;
    wallet: Wallet | null;
    balance: AccountBalance;
    giantGoldBalance: AccountBalance;
    mainWallet: MainWallet | null;
    token: string | null;
    village: Village | null;
    authLoading: boolean;
    authenticated: boolean;
    env: NetworkEnv;
    setState: (account: Account, wallet: Wallet, token: string, village: Village) => void,
    setVillage: (village: Village) => void,
}

const contextDefaultValue: IAuthContext = {
    account: null,
    wallet: null,
    balance: new AccountBalance(0),
    giantGoldBalance: new AccountBalance(0),
    mainWallet: null,
    token: null,
    village: null,
    authLoading: true,
    authenticated: false,
    env: "devnet",
    setState: (account: Account, wallet: Wallet, token: string, village: Village) => {},
    setVillage: (village: Village) => {},
};

type AuthState = {
    account: Account | null;
    wallet: Wallet | null;
    mainWallet: MainWallet;
    token: string | null;
    village: Village | null;
};

export const AuthContext = createContext(contextDefaultValue);
export const AuthContextProvider = (
    {
        children,
        env,
        projectId
    }: PropsWithChildren<{ env: NetworkEnv, projectId?: string }>
) => {
    const [state, setState] = useState<AuthState>(() => ({
        account: null,
        wallet: null,
        token: null,
        village: null,
        mainWallet: buildMainWallet(env, projectId ?? null),
    }));
    const [authLoading, setAuthLoading] = useState(false);
    const authenticated = useMemo<boolean>(() => {
        return !!state.wallet && !!state.account && !!state.token;
    }, [state]);
    const [refreshedAt, setRefreshedAt] = useState(Date.now);
    const {api} = useApi();


    const setAccountState = async (
        account: Account,
        wallet: Wallet,
        token: string,
        village: Village
    ) => {
        await wallet.refresh();
        wallet.setOnRefreshHandler(() => setRefreshedAt(Date.now));
        setState(state => ({...state, account, wallet, token, village}));
    }



    const value = useMemo(() => {
        return {
            ...state,
            balance: state.wallet?.balance ?? new AccountBalance(0),
            giantGoldBalance: state.wallet?.giantGoldBalance ?? new AccountBalance(0),
            authLoading,
            authenticated,
            env,
            setState: setAccountState,
            setVillage: (village: Village) => setState(state => ({...state, village}))
        }
    }, [state, authenticated, state.mainWallet, refreshedAt]);


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const buildMainWallet = (env: NetworkEnv, projectId: string | null) => {
    return new MainWallet(
        new ApiNetworkProvider(network[env]["api"], {timeout: 10000}),
        new ProviderBuilder({
            projectId: projectId ?? "",
            chainId: configNetwork[env].chainId
        })
    )
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within an AuthContextProvider.`);
    }
    return context;
}