import {ChainConfig, NetworkEnv, TransactionStatus, ActionNotificationInfo} from "../common/types";
import {
    ActionStatus,
    EventVillageAction,
    GameActionEnum,
    GameActionValue
} from "../common/types/actions";
import {
    Address, AddressValue,
    ContractFunction,
    Interaction,
    SmartContract,
    Transaction
} from "@multiversx/sdk-core/out";
import {network} from "../config/network";
import axios from "axios";
import {Signature} from "@multiversx/sdk-core/out/signature";
import {Nonce} from "@multiversx/sdk-network-providers/out/primitives";

const txStatusValue = (status: TransactionStatus): number => {
    switch (status) {
        case "invalid":
            return -2;
        case "failed":
            return -1;
        case "new":
            return 0;
        case "pending":
            return 1;
        case "success":
            return 2;
    }
};

export const compareTransactionsOrder = (a: ActionNotificationInfo, b: ActionNotificationInfo): number => {
    const aStatus = txStatusValue(a.status);
    const bStatus = txStatusValue(b.status);

    if (aStatus < bStatus) {return -1;}

    if (aStatus > bStatus) {return 1;}

    if (a.timestamp > b.timestamp) {return -1;}

    if (a.timestamp < b.timestamp) {return 1;}

    return 0;
};

const mapActionStatusToTxStatus = (actionStatus: ActionStatus): TransactionStatus => {
    switch (actionStatus) {
        case "failed":
        case "reverted":
            return "failed";
        case "pending":
            return "pending";
        case "complete":
            return "success"
    }
}

export const buildActionNotificationInfo = (
    action: EventVillageAction,
    jobId?: string
): ActionNotificationInfo => {
    return {
        id: jobId || action.transaction_hash || action.id || "",
        action: action.action as GameActionValue,
        status: mapActionStatusToTxStatus(action.status as ActionStatus),
        hash: action.transaction_hash || "",
        timestamp: action.created_at
            ? new Date(action.created_at).getMilliseconds()
            : Date.now()
    };
}

export const txExplorerUrl = (hash: string): string => {
    const env = process.env.NEXT_PUBLIC_NETWORK_ENV!;
    const baseUrl = env === "mainnet"
        ? "https://explorer.multiversx.com/transactions/"
        : "https://devnet-explorer.multiversx.com/transactions/"

    return baseUrl + hash;
}

export const guardTransactions = async (transactions: Transaction[], code: string) => {
    const env = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
    const url = `${network[env].tools}/guardian/sign-multiple-transactions`;
    const {data: {data}} = await axios.post(url, {
        code,
        transactions: transactions.map(tx => tx.toSendable()),
    });
    const signatures = data.transactions.map(
        (t: { guardianSignature: string }) => t.guardianSignature
    );

    return transactions.map((tx, i) => {
        tx.applyGuardianSignature(new Signature(signatures[i]));

        return tx;
    });
};

export const getChainConfig = async (): Promise<ChainConfig> => {
    const gatewayUrl = process.env.NEXT_PUBLIC_GATEWAY_URL!;
    const {data: {data: {config}}} = await axios.get(`${gatewayUrl}/network/config`);

    return {
        minGasPrice: config.erd_min_gas_price,
        minGasLimit: config.erd_min_gas_limit,
        maxGasLimit: config.erd_max_gas_per_transaction,
        gasPerDataByte: config.erd_gas_per_data_byte,
        gasPriceModifier: parseFloat(config.erd_gas_price_modifier),
        guardedTxExtraGas: config.erd_extra_gas_limit_guarded_tx
    };
};

export const buildAuthorizationTx = (address: string): Transaction => {
    return new Interaction(
        new SmartContract({
            address: Address.fromBech32(process.env.NEXT_PUBLIC_STAKING_CONTRACT!),
        }),
        new ContractFunction("authorizeInventoryWallet"),
        [new AddressValue(Address.fromBech32(address))]
    )

        .buildTransaction();
}
