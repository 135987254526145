import {IToken} from "../common/types/actions";
import {collections, defaultAssetAttributes, TOKEN_GIANTS_FEMALES} from "../config/assets";
import {nftNonceName, nftNonceNameFemale} from "../config/nfts-nonce-name";
import {NetworkEnv} from "../common/types";
import axios from "axios";
import {network} from "../config/network";
import {decToHexStr} from "./converters";
import {decodeBuildingAttributes} from "./decoders";
import {AssetAttributes} from "@elrond-giants/game-db/types/entities";


/**
 * Bugged function. Do not use anymore.
 * @param id
 */
export const getTokenFromIdBugged = (id: string): IToken => {
    const parts = id.split("-");
    if (parts.length < 3) {throw new Error("Invalid NFT identifier");}
    const strNonce = parts.pop();
    const nonce = parseInt(strNonce!, 10);

    return {
        nonce,
        id: parts.join("-")
    };
};

export const getTokenFromId = (id: string): IToken => {
    const parts = id.split("-");
    if (parts.length < 3) {throw new Error("Invalid NFT identifier");}
    const strNonce = parts.pop();
    const nonce = parseInt(strNonce!, 16);

    return {
        nonce,
        id: parts.join("-")
    };
};

export const getDisplayInfo = (token: IToken): { thumbnail: string, name: string } => {
    if (token.id === TOKEN_GIANTS_FEMALES) {
        const nameNumber = nftNonceNameFemale[token.nonce] ?? 1;
        return {
            name: `F.Giants #${nameNumber}`,
            thumbnail: `https://giants.fra1.cdn.digitaloceanspaces.com/females_collection_small/${nameNumber}.jpg`
        }
    }

    const nameNumber = nftNonceName[token.nonce] ?? 1;

    return {
        name: `Giants #${nameNumber}`,
        thumbnail: `https://giants.fra1.cdn.digitaloceanspaces.com/collection_small/${nameNumber}.jpg`
    }


}

export const getTokenAttributes = async (token: IToken): Promise<AssetAttributes> => {
    if (!collections.buildings.includes(token.id)) {
        return defaultAssetAttributes;
    }
    const env: NetworkEnv = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
    const {data: {attributes}} = await axios.get(
        `${network[env].api}/nfts/${token.id}-${decToHexStr(token.nonce)}`
    );

    return decodeBuildingAttributes(attributes);
}

export const getIdFromInventoryAsset = (asset: { collection: string; nonce: number; }): string => {
    const hexNonce = decToHexStr(asset.nonce);

    return `${asset.collection}-${hexNonce}`;
}

export const getNftIdFromToken = (token: IToken): string => {
    const hexNonce = decToHexStr(token.nonce);

    return `${token.id}-${hexNonce}`;
}

export const getNftIdFromVillageAsset = (asset: { asset_token: string; asset_token_nonce: number; }): string => {
    const hexNonce = decToHexStr(asset.asset_token_nonce);

    return `${asset.asset_token}-${hexNonce}`;
}