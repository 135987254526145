import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GiantInventoryAsset, InventoryAsset, InventoryFilters} from "../../common/types/inventory";
import {
    getInventoryAssets,
    getNfts,
    mapNftsToInventoryAssets
} from "../../utils/inventory";
import {RootState} from "../store";
import axios from "axios";



const initialState: {
    address: string;
    assets: InventoryAsset[],
    status: "idle" | "loading" | "complete" | "error";
    nextPageKey: string;
    assetsToTransfer: { [key: string]: number };
    filters: InventoryFilters;
    stakedGiants: GiantInventoryAsset[];
} = {
    address: "",
    assets: [],
    status: "idle",
    nextPageKey: "",
    assetsToTransfer: {},
    filters: {},
    stakedGiants: []
};

export const filterMainWalletAssets = createAsyncThunk(
    "walletAssets/filterAssets",
    (filters: InventoryFilters, {dispatch}) => {
        dispatch(setFilters(filters));
        dispatch(fetchMainWalletAssets({reset: true}));
    }
);

export const fetchMainWalletAssets = createAsyncThunk(
    "walletAssets/fetchAssets",
    async (
        {
            address, reset = false
        }: { address?: string, reset?: boolean },
        thunkAPI
    ) => {
        if (address) {
            thunkAPI.dispatch(setAddress(address));
        }
        const {
            walletAssets: {
                nextPageKey: pageKey,
                address: wallet,
                filters
            }
        } = thunkAPI.getState() as RootState;
        const {assets, nextPageKey} = await getInventoryAssets(
            wallet,
            {reset, pageKey, filters}
        );

        return {assets, nextPageKey, reset};
    }
);

export const fetchStakedGiants = createAsyncThunk(
    "inventory/fetchStakedGiants",
    async (
        {address}: { address?: string },
        thunkAPI
    ) => {
        if (address) {
            thunkAPI.dispatch(setAddress(address));
        }
        const {
            walletAssets: {
                address: walletAddress,
            }
        } = thunkAPI.getState() as RootState;

        const {data: nftIds} = await axios.get(
            `${process.env.NEXT_PUBLIC_STAKING_BASE_URL}/api/staked`,{
                params: {address: walletAddress}
            }
        );

        const nfts = [];
        let i = 0;
        const size = 100;
        while (i < nftIds.length) {
            const nftChunk = await getNfts({
                params: {
                    identifiers: nftIds.slice(i, i + size).join(","),
                    size
                }
            });
            nfts.push(...nftChunk);
            i += size;
        }

        return mapNftsToInventoryAssets(nfts)
            .map(asset => ({...asset, staked: true}));

    }
);

const walletAssetsSlice = createSlice({
    initialState,
    name: "walletAssets",
    reducers: {
        selectAssetToTransfer: (state, action: PayloadAction<{ token: string, qty: number }>) => {
            const {token, qty} = action.payload;
            if (qty > 0) {
                state.assetsToTransfer[token] = qty;
            } else {
                delete state.assetsToTransfer[token];
            }
        },
        clearAssetsToTransfer: (state) => {
            state.assetsToTransfer = {};
        },
        setAddress: (state, action: PayloadAction<string>) => {
            state.address = action.payload;
        },
        setFilters: (state, action: PayloadAction<InventoryFilters>) => {
            state.filters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMainWalletAssets.pending, state => {state.status = "loading"})
            .addCase(fetchMainWalletAssets.rejected, state => {state.status = "error"})
            .addCase(fetchMainWalletAssets.fulfilled, (
                state,
                action: PayloadAction<{ assets: InventoryAsset[], nextPageKey: string, reset: boolean }>
            ) => {
                const {assets, nextPageKey, reset} = action.payload;
                if (reset) {
                    state.assets = assets;
                } else {
                    state.assets.push(...assets);
                }
                state.nextPageKey = nextPageKey;
                state.status = "complete"
            })
            .addCase(fetchStakedGiants.fulfilled, (
                state,
                action: PayloadAction<GiantInventoryAsset[]>
            ) => {
                state.stakedGiants = action.payload;
            });
    },
});

export const {
    selectAssetToTransfer,
    clearAssetsToTransfer,
    setAddress,
    setFilters
} = walletAssetsSlice.actions;
export const hasMoreWalletAssets = (state: RootState) => state.walletAssets.nextPageKey !== "";
export default walletAssetsSlice.reducer;