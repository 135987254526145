import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Leaderboard, LeaderboardEntry} from "@elrond-giants/game-db/types/entities";
import {ShowAccountOnLeaderboardResponse} from "../../common/types/responses";

type StateType = {
    status: "idle" | "loading" | "complete" | "error";
    leaderboard: Leaderboard | null;
    accountEntry: LeaderboardEntry | null;
    previousLeaderboard: Leaderboard | null;
    previousAccountEntry: LeaderboardEntry | null;
    previousLeaderboardStatus: "idle" | "loading" | "complete" | "error";
    claimablePrizesAmount: string | null;
    prizeStatus: "idle" | "loading" | "complete" | "error";
    claiming: boolean;
};

const initialState: StateType = {
    status: "idle",
    leaderboard: null,
    accountEntry: null,
    previousLeaderboard: null,
    previousAccountEntry: null,
    previousLeaderboardStatus: "idle",
    claimablePrizesAmount: null,
    prizeStatus: "idle",
    claiming: false
};

export const fetchAccountOnLeaderboard = createAsyncThunk(
    "leaderboard/getAccountOnLeaderboard",
    async ({fetch}: { fetch: () => Promise<ShowAccountOnLeaderboardResponse> }) => {
        return await fetch();
    }
);

export const fetchAccountOnPreviousLeaderboard = createAsyncThunk(
    "leaderboard/getAccountOnPreviousLeaderboard",
    async ({fetch}: { fetch: () => Promise<ShowAccountOnLeaderboardResponse> }) => {
        return await fetch();
    }
);

export const fetchClaimablePrizesAmount = createAsyncThunk(
    "leaderboard/getClaimablePrizesAmount",
    async ({fetch}: { fetch: () => Promise<string> }) => {
        return await fetch();
    }
);

const slice = createSlice({
    initialState,
    name: "leaderboard",
    reducers: {
        setClaiming: (state, action) => {
            state.claiming = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAccountOnLeaderboard.pending, state => {
                state.status = "loading";
            })
            .addCase(fetchAccountOnLeaderboard.rejected, state => {
                state.status = "error";
            })
            .addCase(fetchAccountOnLeaderboard.fulfilled, (state, action) => {
                state.status = "complete";
                state.leaderboard = {
                    entries: action.payload.leaderboard.entries,
                    segment: action.payload.leaderboard.segment
                };
                state.accountEntry = action.payload.leaderboard.accountEntry;
            })
            .addCase(fetchClaimablePrizesAmount.pending, state => {
                state.prizeStatus = "loading";
            })
            .addCase(fetchClaimablePrizesAmount.rejected, state => {
                state.prizeStatus = "error";
            })
            .addCase(fetchClaimablePrizesAmount.fulfilled, (state, action) => {
                state.prizeStatus = "complete";
                state.claimablePrizesAmount = action.payload;
            })
            .addCase(fetchAccountOnPreviousLeaderboard.pending, state => {
                state.previousLeaderboardStatus = "loading";
            })
            .addCase(fetchAccountOnPreviousLeaderboard.rejected, state => {
                state.previousLeaderboardStatus = "error";
            })
            .addCase(fetchAccountOnPreviousLeaderboard.fulfilled, (state, action) => {
                state.previousLeaderboardStatus = "complete";
                state.previousLeaderboard = {
                    entries: action.payload.leaderboard.entries,
                    segment: action.payload.leaderboard.segment
                };
                state.previousAccountEntry = action.payload.leaderboard.accountEntry;
            });
    }
});

export const {setClaiming} = slice.actions;
export default slice.reducer;