import {ApiNetworkProvider} from "@multiversx/sdk-network-providers/out";
import AccountBalance from "./AccountBalance";
import {Address, Transaction} from "@multiversx/sdk-core/out";
import {AuthProviderType, IAuthProvider} from "@elrond-giants/erdjs-auth/dist/types";
import ProviderBuilder from "./ProviderBuilder";
import {GuardianData} from "@multiversx/sdk-network-providers/out/accounts";
import {GUARDIAN_SERVICE_ID_INVISIBLE} from "../config/misc";
import {TOKEN_GIANTS_CURRENCY} from "../config/assets";


export default class MainWallet {
    private readonly networkProvider: ApiNetworkProvider;
    private readonly authProviderBuilder: ProviderBuilder;
    private authProvider: IAuthProvider | undefined;
    private _nonce: number = 0;
    private _balance: AccountBalance = new AccountBalance(0);
    private _giantGoldBalance: AccountBalance = new AccountBalance(0);
    private _connected = false;
    private _guardian: GuardianData;

    constructor(networkProvider: ApiNetworkProvider, authProviderBuilder: ProviderBuilder) {
        this.networkProvider = networkProvider;
        this.authProviderBuilder = authProviderBuilder;
        this._guardian = new GuardianData({guarded: false});
    }

    private async buildAuthProvider(
        providerType: AuthProviderType,
        onLogin: ((address: string, signature: string) => void) | null = null
    ) {
        this.authProvider = this.authProviderBuilder.buildProvider(providerType);
        this.authProvider.on("login", () => {
            this._connected = true;
            this.refresh();
            if (typeof onLogin === "function") {
                const address = this.authProvider!.getAddress();
                const signature = this.authProvider!.getSignature();
                if (address && signature) {
                    onLogin(address, signature);
                }
            }
        });
        this.authProvider.on("logout", () => {
            this._connected = false;
        });

        await this.authProvider.init();

    }

    get balance(): AccountBalance {
        return this._balance;
    }

    get giantGoldBalance(): AccountBalance {
        return this._giantGoldBalance;
    }

    get nonce(): number {
        return this._nonce;
    }

    get address(): string | null {
        return this.authProvider?.getAddress() ?? null;
    }

    get connected(): boolean {
        return this._connected;
    }

    get guardian(): GuardianData {
        return this._guardian;
    }

    async login(
        providerType: AuthProviderType,
        authToken: string,
        callback: ((address: string, signature: string) => void) | null = null
    ) {
        await this.buildAuthProvider(providerType, callback);
        let options = {};
        if (providerType === AuthProviderType.WALLET_CONNECT) {
            options = {methods: ["mvx_signNativeAuthToken", "mvx_cancelAction"]};
        }

        return await this.authProvider!.login(authToken, options);

    }

    async logout() {
        return this.authProvider?.logout();
    }

    async refresh() {
        if (!this._connected) {return;}
        const address = Address.fromBech32(this.address!);
        const {
            balance,
            nonce
        } = await this.networkProvider.getAccount(address);
        this._guardian = await this.networkProvider.getGuardianData(address);
        try {
            const {
                balance: giantsGoldBalance
            } = await this.networkProvider.getFungibleTokenOfAccount(address, TOKEN_GIANTS_CURRENCY);
            this._giantGoldBalance = new AccountBalance(giantsGoldBalance);
        } catch (e) {
            this._giantGoldBalance = new AccountBalance(0);
        }

        this._nonce = nonce.valueOf();
        this._balance = new AccountBalance(balance);

    }

    async signTransaction(tx: Transaction): Promise<Transaction | null>{
        const _tx = await this.authProvider!.signTransaction(tx);
        if (!_tx) {return null;}

        return _tx as Transaction;
    }

    shouldApplyGuardianSignature(): boolean {
        if (!this.guardian.guarded) {
            return false;
        }
        if (this.guardian.activeGuardian?.serviceUID === GUARDIAN_SERVICE_ID_INVISIBLE) {
            return false;
        }
        if (!this.authProvider) {
            return false;
        }

        return [
            AuthProviderType.LEDGER,
            AuthProviderType.PEM,
            AuthProviderType.WALLET_CONNECT,
        ].includes(this.authProvider.getType());
    }
};