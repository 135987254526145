import {
    BigUIntType, BigUIntValue, BytesType, BytesValue, EnumType, Field,
    FieldDefinition, List, ListType, StringType, Struct,
    Address,
    AddressType, AddressValue,
    StringValue,
    StructType,
    TokenIdentifierType, TokenIdentifierValue, U32Type, U32Value,
    U64Type, U64Value, OptionType
} from "@multiversx/sdk-core/out";
import {TokenPayload} from "../common/types/actions";
import {UserQuest} from "@elrond-giants/game-db/types/entities";
import {ReferralTierRewardToken} from "@elrond-giants/game-db/types/entities";
import {buildings} from "../config/assets";
import {getFarmAssetInfoByRewardToken} from "./assets";

export const EsdtTokenPaymentType = new StructType("EsdtTokenPayment", [
    new FieldDefinition("token_identifier", "", new TokenIdentifierType()),
    new FieldDefinition("token_nonce", "", new U64Type()),
    new FieldDefinition("amount", "", new BigUIntType()),
]);

export const buildEsdtTokenPayment = (token: TokenPayload) => {
    return new Struct(EsdtTokenPaymentType, [
        new Field(
            TokenIdentifierValue.esdtTokenIdentifier(token.id),
            "token_identifier"
        ),
        new Field(new U64Value(token.nonce), "token_nonce"),
        new Field(new BigUIntValue(token.quantity ?? 1), "amount")
    ]);
}

const structTypeBuildingType = EnumType.fromJSON({
    name: "BuildingType",
    variants: [
        {
            "name": "WoodFarm",
            "discriminant": 0
        },
        {
            "name": "FoodFarm",
            "discriminant": 1
        },
        {
            "name": "ClayFarm",
            "discriminant": 2
        },
        {
            "name": "House",
            "discriminant": 3
        }
    ],
});

export const buildingAttributesType = new StructType(
    "BuildingAttributes",
    [
        new FieldDefinition("width", "width", new U64Type()),
        new FieldDefinition("height", "height", new U64Type()),
        new FieldDefinition("level", "level", new U64Type()),
        new FieldDefinition("building_type", "building_type", structTypeBuildingType),
        new FieldDefinition("metadata", "metadata", new StringType()),
        new FieldDefinition("skin", "skin", new OptionType(EsdtTokenPaymentType)),
    ]
);

export const ReferralItemType = new StructType("ReferralItem", [
    new FieldDefinition("id", "id", new U64Type()),
    new FieldDefinition("rewards", "rewards", new ListType(EsdtTokenPaymentType)),
    new FieldDefinition("signature", "signature", new BytesType()),
]);

export const buildReferralItem = ({id, rewards, signature}: {
    id: number;
    rewards: ReferralTierRewardToken[];
    signature: string
}): Struct => {
    const tokenPayments = rewards.map(r => buildEsdtTokenPayment({
        id: r.id,
        nonce: r.nonce,
        quantity: r.amount
    }));

    return new Struct(ReferralItemType, [
        new Field(new U64Value(id), "id"),
        new Field(new List(new ListType(EsdtTokenPaymentType), tokenPayments), "rewards"),
        new Field(BytesValue.fromHex(signature), "signature"),
    ]);
};

export const BuyShopItemPayloadType = new StructType("BuyShopItemPayload", [
    new FieldDefinition("token_identifier", "", new TokenIdentifierType()),
    new FieldDefinition("quantity", "", new U64Type()),
]);

export const buildBuyShopItemPayload = (token: string, qty: number) => {
    return new Struct(BuyShopItemPayloadType, [
        new Field(TokenIdentifierValue.esdtTokenIdentifier(token), "token_identifier"),
        new Field(new U64Value(qty), "quantity"),
    ]);
};

export const QuestType = new StructType("Quest", [
    new FieldDefinition("id", "id", new U64Type()),
    new FieldDefinition("vc_rewards", "vc rewards", new BigUIntType()),
    new FieldDefinition("giant_rewards", "giant rewards", new BigUIntType()),
    new FieldDefinition("npc_xp_rewards", "npc xp", new U64Type()),
]);

export const buildQuestStruct = (quest: UserQuest) => {
    return new Struct(QuestType, [
        new Field(new U64Value(quest.id), "id"),
        new Field(new BigUIntValue(quest.reward_qty), "vc_rewards"),
        new Field(new BigUIntValue(quest.giant_qty), "giant_rewards"),
        new Field(new U64Value(quest.npc_reward_qty), "npc_xp_rewards"),
    ]);
};

export const ExternalMintItemType = new StructType("ExternalMintItem", [
    new FieldDefinition("sc_address", "", new AddressType()),
    new FieldDefinition("sc_endpoint", "", new StringType()),
    new FieldDefinition("recipient", "", new AddressType()),
    new FieldDefinition("quantity", "", new BigUIntType()),
]);

export const buildExternalMintItem = (scAddress: string, scEndpoint: string, recipient: string, qty: number) => {
    return new Struct(ExternalMintItemType, [
        new Field(new AddressValue(Address.fromBech32(scAddress)), "sc_address"),
        new Field(new StringValue(scEndpoint), "sc_endpoint"),
        new Field(new AddressValue(Address.fromBech32(recipient)), "recipient"),
        new Field(new BigUIntValue(qty), "quantity"),
    ]);
};

export const ClaimResourceItemType = new StructType("ClaimResourceItem", [
    new FieldDefinition("sc_address", "", new AddressType()),
    new FieldDefinition("quantity", "", new BigUIntType()),
]);

export const buildClaimResourceItem = (scAddress: string, qty: number) => {
    return new Struct(ClaimResourceItemType, [
        new Field(new AddressValue(Address.fromBech32(scAddress)), "sc_address"),
        new Field(new BigUIntValue(qty), "quantity"),
    ]);
};

export const createResourceClaimItemFromToken = (token: Required<TokenPayload>) => {
    const farmInfo = getFarmAssetInfoByRewardToken(token);

    return buildClaimResourceItem(farmInfo.contract, token.quantity);

}

export const LeaderboardClaimPrizeItemType = new StructType("LeaderboardClaimPrizeItem", [
    new FieldDefinition("nonce", "", new U64Type()),
    new FieldDefinition("amount", "", new BigUIntType()),
    new FieldDefinition("signature", "", new BytesType()),
]);

export const buildLeaderboardClaimPrizeItem = (nonce: number, amount: string, signature: string) => {
    return new Struct(LeaderboardClaimPrizeItemType, [
        new Field(new U64Value(nonce), "nonce"),
        new Field(new BigUIntValue(amount), "amount"),
        new Field(BytesValue.fromHex(signature), "signature"),
    ]);
};