import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PartialVillageAsset} from "../../common/types/repository";
import {getIsInventoryWalletAuthorized} from "../../utils/contractQueries";
import {GetUpgradingBuildingsQtyResponse} from "../../common/types/responses";

type StateType = {
    selectedAsset: PartialVillageAsset | null;
    walletAuthorizedForStaking: boolean;
    placedLandQty: number;
    placedBuildingsQty: number;
    upgradingBuildingsQty: number | null;
};
const initialState: StateType = {
    selectedAsset: null,
    walletAuthorizedForStaking: false,
    placedLandQty: 0,
    placedBuildingsQty: 0,
    upgradingBuildingsQty: null
};

export const fetchAuthorizedWalletForStaking = createAsyncThunk(
    "village/getAuthorizedWalletForStaking",
    async (address: string) => {
        return await getIsInventoryWalletAuthorized(address);
    }
);

export const fetchPlacedLandQty = createAsyncThunk(
    "village/getPlacedLandQty",
    async ({fetch}: { fetch: () => Promise<number> }) => {
        return await fetch();
    }
);

export const fetchUpgradingBuildingsQty = createAsyncThunk(
    "village/getUpgradingBuildingsQty",
    async ({fetch}: {
        fetch: () => Promise<GetUpgradingBuildingsQtyResponse>
    }) => {
        return await fetch();
    }
);

const slice = createSlice({
    initialState,
    name: "village",
    reducers: {
        setSelectedAsset: (
            state,
            action: PayloadAction<PartialVillageAsset | null>
        ) => {
            // @ts-ignore
            state.selectedAsset = action.payload;
        },
        setPlacedLandQty: (
            state,
            action: PayloadAction<number>
        ) => {
            state.placedLandQty = action.payload;
        },
        increaseUpgradingBuildingsQty: (
            state,
        ) => {
            state.upgradingBuildingsQty = (state.upgradingBuildingsQty ?? 0) + 1;
        },
        decreaseUpgradingBuildingsQty: (
            state,
        ) => {
            if (!state.upgradingBuildingsQty) {return;}
            state.upgradingBuildingsQty--;
        },
        increasePlacedBuildingsQty: (
            state,
        ) => {
            state.placedBuildingsQty++;
        },
        decreasePlacedBuildingsQty: (
            state,
        ) => {
            state.placedBuildingsQty--;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthorizedWalletForStaking.fulfilled, (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.walletAuthorizedForStaking = action.payload;
        })
            .addCase(fetchPlacedLandQty.fulfilled, (
                state,
                action: PayloadAction<number>
            ) => {
                state.placedLandQty = action.payload;
            })
            .addCase(fetchUpgradingBuildingsQty.fulfilled, (
                state,
                action: PayloadAction<GetUpgradingBuildingsQtyResponse>
            ) => {
                state.upgradingBuildingsQty = action.payload.upgradingBuildingsQty;
                state.placedBuildingsQty = action.payload.placedBuildingsQty;
            });
    }
});
export const {
    setSelectedAsset,
    increaseUpgradingBuildingsQty,
    decreaseUpgradingBuildingsQty,
    increasePlacedBuildingsQty,
    decreasePlacedBuildingsQty,
} = slice.actions;
export default slice.reducer;