import {Address, TokenPayment, Transaction} from '@multiversx/sdk-core/out';
import axios, {AxiosRequestConfig} from 'axios';
import {ListCursor, NetworkEnv, Nft, NftFilters, WithRequired} from '../common/types';
import {
  BuildingResident,
  InventoryAsset,
  InventoryFilters,
  InventoryFilterType
} from '../common/types/inventory';
import {VillageResident} from '@elrond-giants/game-db/types/entities';
import {
  collections,
  TOKEN_BUILDING_FOOD, TOKEN_BUILDING_HOUSE,
  TOKEN_BUILDING_STONE,
  TOKEN_BUILDING_WOOD
} from '../config/assets';
import {network} from '../config/network';
import {getDisplayInfo} from './nfts';
import {MultiESDTNFTTransferPayloadBuilder} from "@multiversx/sdk-core/out/tokenTransferBuilders";
import {decodeBuildingAttributes} from "./decoders";

export const NFTS_PAGE_SIZE = 30;
export const getInventoryAssets = async (
  address: string,
  { filters, reset = false, pageKey = "0" }: ListCursor & { filters?: InventoryFilters }
): Promise<{ assets: InventoryAsset[]; nextPageKey: string }> => {
  const from = reset ? "0" : pageKey;
  const nftFilters = mapInventoryFilters(filters ?? {});
  const tokens = await getAccountNfts(address, {
    params: {
      ...nftFilters,
      from,
      size: NFTS_PAGE_SIZE,
    },
  });

  let nextPageKey = "";
  if (tokens.length === NFTS_PAGE_SIZE) {
    nextPageKey = (parseInt(from) + NFTS_PAGE_SIZE).toString();
  }

  const assets = mapNftsToInventoryAssets(tokens);

  return { assets, nextPageKey };
};

export const mapNftsToInventoryAssets = (nfts: Nft[]): InventoryAsset[] => {
  return nfts.map((token: Nft) => {
    let attributes;
    if (collections.buildings.includes(token.collection)) {
      attributes = decodeBuildingAttributes(token.attributes);
    }

    return {
      attributes,
      identifier: token.identifier,
      quantity: parseInt(token.balance ?? "0"),
      thumbnail: token.url,
      type: token.type,
      collection: token.collection,
      nonce: token.nonce,
      name: token.name
    }
  });
};

export const mapInventoryFilterToCollections = (filter?: InventoryFilterType): string[] => {
  let collectionsFilter;
  switch (filter) {
    case "assets":
      collectionsFilter = [...collections.buildings, ...collections.land];
      break;
    case "resources":
      collectionsFilter = [...collections.rewards];
      break;
    case "giants":
      collectionsFilter = [...collections.giants];
      break;
    case "land":
      collectionsFilter = [...collections.land];
      break;
    case "skins":
      collectionsFilter = [...collections.skins];
      break;
    default:
      collectionsFilter = [
        ...collections.buildings,
        ...collections.land,
        ...collections.rewards,
        ...collections.giants,
        ...collections.skins,
      ];
  }

  return collectionsFilter;
}

const mapInventoryFilters = ({ type }: InventoryFilters): NftFilters => {
  const collectionsFilter = mapInventoryFilterToCollections(type);
  return { collections: collectionsFilter.join(",") };
};

export const buildInventoryAssetTx = (assets: InventoryAsset[], sender: Address, receiver: Address) => {
  const payments = [];

  for (let asset of assets) {
    if (asset.type === "SemiFungibleESDT") {
      payments.push(TokenPayment.semiFungible(asset.collection, asset.nonce, asset.quantity));
    } else {
      payments.push(TokenPayment.nonFungible(asset.collection, asset.nonce));
    }
  }

  const payload = new MultiESDTNFTTransferPayloadBuilder().setPayments(payments).setDestination(receiver).build();

  const env = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
  const chainId = network[env].chainId;

  return new Transaction({
    sender,
    receiver: sender,
    data: payload,
    chainID: chainId,
    gasLimit: 50000 + 1500 * payload.length() + 1000000 * payments.length,
  });
};
const workTitle = (buildingCollection: string): string => {
  switch (buildingCollection) {
    case TOKEN_BUILDING_WOOD:
      return "Working at the lumber-mill";
    case TOKEN_BUILDING_FOOD:
      return "Harvesting food";
    case TOKEN_BUILDING_STONE:
      return "Working at the quarry";
    case TOKEN_BUILDING_HOUSE:
      return "Chilling at home"

    default:
      return "";
  }
};
export const mapVillageResidents = (
    residents: WithRequired<VillageResident, "traits" | "worker">[]
): BuildingResident[] => {
  return residents.map((resident) => {
    const token = {
      id: resident.giant_token!,
      nonce: resident.giant_token_nonce!
    };
    const working = !!resident.worker;
    const {name, thumbnail} = getDisplayInfo(token);

    return {
      thumbnail,
      working,
      name,
      collection: resident.giant_token!,
      nonce: resident.giant_token_nonce!,
      quantity: 1,
      type: "NonFungibleESDT",
      workTitle: workTitle(working ? resident.worker!.building_token : resident.house_token),
      identifier: resident.nft_identifier,
      status: working ? resident.worker!.status : resident.status,
      selectable: true,
      staked: resident.staked,
      traits: resident.traits
    };
  });
};

export const getAccountNfts = async (address: string, config?: AxiosRequestConfig): Promise<Nft[]> => {
  const env: NetworkEnv = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
  const {data} = await axios.get(
      `${network[env].api}/accounts/${address}/nfts`,
      config
  );

  return data;
}

export const getNfts = async (config?: AxiosRequestConfig): Promise<Nft[]> => {
  const env: NetworkEnv = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
  const {data} = await axios.get(
      `${network[env].api}/nfts`,
      config
  );

  return data;
}

export const countNfts = async (address: string, collections: string[]): Promise<number> => {
  const env: NetworkEnv = process.env.NEXT_PUBLIC_NETWORK_ENV! as NetworkEnv;
  const {data} = await axios.get(`${network[env].api}/accounts/${address}/nfts/count`, {
    params: {
      collections: collections.join(",")
    }
  });

  return data;
}
