import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IToken} from "../../common/types/actions";
import {VillageResident} from "@elrond-giants/game-db/types/entities";
import {RootState} from "../store";
import {createSelector} from "reselect";
import {selectInventoryGiants} from "./inventorySlice";
import {GiantInventoryAsset, SelectedResident} from "../../common/types/inventory";
import {getNftIdFromToken, } from "../../utils/nfts";
import {WithRequired} from "../../common/types";


const initialState: {
    village: {
        id: string;
        ownerId: string;
    } | null;
    selected: SelectedResident[];
    residents: WithRequired<VillageResident, "traits" | "worker">[];
    status: "idle" | "loading" | "complete" | "error";
} = {
    selected: [],
    residents: [],
    status: "idle",
    village: null
};

export const fetchVillageResidents = createAsyncThunk(
    "village/fetchResidents",
    async (
        {fetch}: {
            fetch: (villageId: string) => Promise<{
                residents: WithRequired<VillageResident, "traits" | "worker">[]
            }>
        },
        {getState}
    ) => {
        const {residents: {village}} = getState() as RootState;
        if (!village) {throw new Error("Village not set");}
        const {residents} = await fetch(village.id);

        return residents;
    }
);

const slice = createSlice({
    name: "residents",
    initialState,
    reducers: {
        selectResident: (state, action: PayloadAction<SelectedResident>) => {
            const idx = state.selected.findIndex(
                t => t.id === action.payload.id && t.nonce === action.payload.nonce
            );
            if (idx < 0) {
                state.selected.push(action.payload);
            }
        },
        deselectResident: (state, action: PayloadAction<IToken>) => {
            const idx = state.selected.findIndex(
                t => t.id === action.payload.id && t.nonce === action.payload.nonce
            );
            if (idx > -1) {
                state.selected.splice(idx, 1);
            }
        },
        clearSelected: (state) => {state.selected = []},
        setVillage: (state, action: PayloadAction<{ id: string, ownerId: string } | null>) => {
            if (action.payload) {
                const {id, ownerId} = action.payload;
                state.village = {id, ownerId};
            } else {
                state.village = null;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchVillageResidents.pending, state => {state.status = "loading"})
            .addCase(fetchVillageResidents.rejected, state => {state.status = "error"})
            .addCase(fetchVillageResidents.fulfilled, (
                state,
                action: PayloadAction<WithRequired<VillageResident, "traits" | "worker">[]>
            ) => {
                // @ts-ignore
                state.residents = action.payload;
                state.status = "complete";
            });
    }
});

export const {selectResident, deselectResident, clearSelected, setVillage} = slice.actions;
export const selectAvailableFarmResidents = createSelector(
    (state: RootState) => state.residents.residents,
    (residents: WithRequired<VillageResident, "traits" | "worker">[]) => {
        return residents.filter(resident => !resident.worker
            && resident.status === "complete"
        );
    }
);
export const selectPendingResidents = (state: RootState) => state.residents.residents.filter(
    resident => resident.status === "pending"
);
export const selectAvailableHouseResidents = createSelector(
    selectInventoryGiants,
    (state: RootState) => state.residents.residents,
    (
        inventoryGiants: GiantInventoryAsset[],
        residents: WithRequired<VillageResident, "traits" | "worker">[]
    ) => {
        const residentIds = residents.map(resident => {
            return getNftIdFromToken({
                id: resident.giant_token!,
                nonce: resident.giant_token_nonce!
            });
        });
        return inventoryGiants.filter(
            giant => !residentIds.includes(getNftIdFromToken({
                id: giant.collection,
                nonce: giant.nonce
            }))
        );
    }
);

export default slice.reducer;