import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ActionNotificationInfo} from "../../common/types";
import {RootState} from "../store";
import {compareTransactionsOrder} from "../../utils/transactions";


const initialState: {
    transactions: ActionNotificationInfo[],
    transactionsToSign: string[],
    showList: boolean
} = {
    transactions: [],
    transactionsToSign: [],
    showList: false,
};

const slice = createSlice({
    initialState,
    name: "transactions",
    reducers: {
        addOrReplaceTransaction: (
            state,
            action: PayloadAction<ActionNotificationInfo>
        ) => {
            const idx = state.transactions.findIndex(
                t => t.id === action.payload.id || (t.hash === action.payload.hash && t.hash !== "")
            );
            if (idx > -1) {
                state.transactions.splice(idx, 1, action.payload);
            } else {
                state.transactions.push(action.payload);
            }
        },
        removeTransaction: (
            state,
            action: PayloadAction<string>
        ) => {
            const idx = state.transactions.findIndex(t => t.id === action.payload);
            if (idx > -1) {
                state.transactions.splice(idx, 1);
            }
        },
        clearAllTransactions: (state) => {
            state.transactions = [];
        },
        setShowTxList: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.showList = action.payload;
        },
        addTxToSign: (
            state,
            action: PayloadAction<string>
        ) => {
            state.transactionsToSign.push(action.payload);
        },
        removeTxToSign: (
            state,
            action: PayloadAction<string>
        ) => {
            const idx = state.transactionsToSign.findIndex(t => t === action.payload);
            if (idx > -1) {
                state.transactionsToSign.splice(idx, 1);
            }
        }
    }
});

export const {
    addOrReplaceTransaction,
    removeTransaction,
    clearAllTransactions,
    setShowTxList,
    addTxToSign,
    removeTxToSign
} = slice.actions;
export const selectTransactions = (state: RootState) => {
    return [...state.transactions.transactions].sort(compareTransactionsOrder);
};
export const selectTxNotificationsNumber = (state: RootState) => {
    return state.transactions.transactions.filter(
        t => ["pending", "invalid", "failed"].includes(t.status)
    ).length;
}
export const selectShowTxSignInfo = (state: RootState) => {
    return state.transactions.transactionsToSign.length > 0;
}
export default slice.reducer;
