import {Dialog, Transition} from '@headlessui/react';
import {Fragment, MutableRefObject, PropsWithChildren, useEffect, useRef} from 'react';
import {AiOutlineClose} from 'react-icons/ai';
import {classNames} from '../../utils/presentation';
import {useNodeRef} from "../../hooks/useNodeRef";

type PopupProps = PropsWithChildren<{
    open: boolean;
    setOpen: (value: boolean) => void;
    closeButton?: boolean;
    closeOnOutsideClick?: boolean;
    widthClass?: string;
    paddingClass?: string;
    hasBorder?: boolean;
    allowFullScreen?: boolean;
    allignTop?: boolean;
    initialFocus?: MutableRefObject<HTMLElement | null>;
    setHeight?: (height: number) => void;
}>;

export default function Popup(
    {
        children,
        setOpen,
        initialFocus,
        open = false,
        closeButton = true,
        closeOnOutsideClick = true,
        widthClass = "w-full md:max-w-screen-sm lg:max-w-screen-md",
        paddingClass = "p-4",
        hasBorder = true,
        allowFullScreen = true,
        allignTop = false,
        setHeight
    }: PopupProps
) {
    const [containerNode, containerRef] = useNodeRef();
    const onCloseHandler = (value: boolean) => {
        if (!closeOnOutsideClick) {
            return;
        }
        setOpen(value);
    };

    useEffect(() => {
        if (containerNode && setHeight) {
            setHeight(containerNode.clientHeight);
        }
    }, [containerNode]);

  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
            as="div"
            className="fixed z-40 inset-0 overflow-y-auto"
            onClose={onCloseHandler}
            static={true}
            initialFocus={initialFocus}
        >
          <div
              className={classNames(
                  !allowFullScreen && allignTop ? "items-start pt-40 md:items-center md:top-0" : "items-center",
                  "flex  justify-center h-[100svh] text-center sm:block sm:p-0"
              )}
          >
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="hidden md:fixed inset-0 bg-gray-500 bg-opacity-10 transition-opacity"
                />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
             &#8203;
            </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel
                    className={classNames(
                        widthClass,
                        allowFullScreen ? "h-[100dvh] pt-[4.8rem]" : "",
                        "md:h-auto md:shadow-xl md:inline-block transform transition-all sm:my-8 sm:align-middle md:pt-0"
                    )}
                >
                    <div
                        className="h-full bg-secondary-lightest p-2 relative"
                    >
                        <div
                            ref={containerRef}
                            className={classNames(
                                paddingClass,
                                hasBorder ? "border border-secondary" : "",
                                "flex flex-col h-full"
                            )}
                        >
                            <div className="absolute top-6 right-6">
                                {closeButton && (
                                    <button
                                        type="button"
                                        className="rounded-full text-secondary focus:outline-none focus:ring-2 focus:ring-secondary"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <AiOutlineClose className="h-5 w-5" aria-hidden="true"/>
                                    </button>
                                )}
                            </div>
                            {children}
                        </div>
                    </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
