import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NpcWithUserQuests} from "../../common/types/entities";
import {GetQuestsActionResponse} from "../../common/types/responses";
import {createSelector} from "reselect";
import {RootState} from "../store";


const initialState: {
    items: NpcWithUserQuests[];
    status: "idle" | "loading" | "complete" | "error";
    selectedNpcId: string | null;
    waitingToCompleteQuest: boolean;
} = {
    items: [],
    status: "idle",
    selectedNpcId: null,
    waitingToCompleteQuest: false
}

export const fetchQuests = createAsyncThunk(
    "quests/fetchQuests",
    async (
        {fetch}: { fetch: () => Promise<GetQuestsActionResponse> }
    ) => {
        const {npcsWithQuests} = await fetch();

        return npcsWithQuests;
    }
);

const slice = createSlice({
    name: "quests",
    initialState,
    reducers: {
        setSelectedNpcId(state, action: PayloadAction<string | null>) {
            state.selectedNpcId = action.payload;
        },
        setQuestStatus(state, action: PayloadAction<{ userQuestId: number, status: string }>) {
            const {userQuestId, status} = action.payload;
            for (const npc of state.items) {
                const quest = npc.quests.find(quest => quest.id === userQuestId);
                if (quest) {
                    quest.status = status;
                    break;
                }
            }
        },
        clearQuests(state) {
            state.items = [];
        },
        setWaitingToCompleteQuest(state, action: PayloadAction<boolean>) {
            state.waitingToCompleteQuest = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchQuests.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(fetchQuests.fulfilled, (state, action) => {
            state.status = "complete";
            state.items = action.payload;
        });
        builder.addCase(fetchQuests.rejected, (state) => {
            state.status = "error";
        });
    }
});
export const selectCurrentNpc = createSelector(
    (state: RootState) => state.quests.selectedNpcId,
    (state: RootState) => state.quests.items,
    (selectedNpcId, npcsWithQuests) => {
        if (!selectedNpcId) {
            return null;
        }
        return npcsWithQuests.find(npc => npc.npc.id === selectedNpcId);
    }
);
export const {setSelectedNpcId, setQuestStatus, clearQuests, setWaitingToCompleteQuest} = slice.actions;
export default slice.reducer;
