import { NetworkConfig } from "../common/types";

export const network: NetworkConfig = {
  mainnet: {
    api: "https://elrond-api.blastapi.io/48a2dc87-7afc-4047-b05f-2c340adb85f5",
    chainId: "1",
    tools: "https://mainnet-tools.multiversx.com",
  },
  devnet: {
    // api: "https://multiversx-api-devnet.public.blastapi.io",
    api: "https://devnet-api.multiversx.com",
    chainId: "D",
    tools: "https://devnet-tools.multiversx.com",
  },
  testnet: {
    api: "https://testnet-api.multiversx.com",
    chainId: "T",
    tools: "https://testnet-tools.multiversx.com",
  },
};

export const gatewayUrl = process.env.NEXT_PUBLIC_GATEWAY_URL!;
