import {PropsWithChildren} from "react";
import {classNames} from "../../utils/presentation";

type Props = {
    onClick: () => void
    style?: "primary" | "secondary"
    widthClass?: string
    [key: string]: any
};
export default function ActionButton(
    {
        children,
        onClick,
        style = "primary",
        widthClass = "w-full",
        ...props
    }: PropsWithChildren<Props>
) {
    const styleClass = style === "primary"
        ? "bg-[#161616] text-theme-text-lighter focus-visible:outline-[#161616]"
        : "bg-secondary text-theme-text focus-visible:outline-secondary";

    return (
        <button
            onClick={onClick}
            className={classNames(
                widthClass,
                "flex items-center justify-center uppercase py-2 px-3 text-sm",
                "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                "disabled:bg-gray-300 disabled:cursor-not-allowed",
                styleClass
            )}
            {...props}
        >
            {children}
        </button>
    );
}