import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ReferralTier, ReferralRewardStatus, ReferralSpendingsClaim} from "@elrond-giants/game-db/types/entities";
import {GetReferralRewardsResponse} from "../../common/types/responses";
import {createSelector} from "reselect";
import {RootState} from "../store";
import {ReferralRewardWithTier} from "../../common/types/entities";
import BigNumber from "bignumber.js";


const initialState: {
    rewards: ReferralRewardWithTier[];
    lockedRewardTiers: ReferralTier[];
    status: "idle" | "loading" | "complete" | "error";
    totalInvited: number;
    reachedLevels: Record<number, number>;
    nbReferrals: number;
    nbEligible: number;
    claimableAmount: string;
    referralSpendingClaim: ReferralSpendingsClaim | null;
} = {
    rewards: [],
    lockedRewardTiers: [],
    status: "idle",
    totalInvited: 0,
    reachedLevels: {},
    nbReferrals: 0,
    nbEligible: 0,
    claimableAmount: "0",
    referralSpendingClaim: null
}

export const fetchReferralRewards = createAsyncThunk(
    "referralRewards/fetchRewards",
    async (
        {fetch}: { fetch: () => Promise<GetReferralRewardsResponse> }
    ) => {
        return await fetch();
    }
);

export const fetchVillageLevels = createAsyncThunk(
    "referralRewards/fetchVillageLevels",
    async (
        {fetch}: { fetch: () => Promise<{totalInvited: number, reachedLevels: Record<number, number>}> }
    ) => {
        return await fetch();
    }
);

export const fetchNbReferrals = createAsyncThunk(
    "referralRewards/fetchNbReferrals",
    async (
        {fetch}: { fetch: () => Promise<{ totalInvited: number, totalEligible: number }> }
    ) => {
        return await fetch();
    }
);

export const fetchClaimableAmount = createAsyncThunk(
    "referralRewards/fetchClaimableAmount",
    async (
        {fetch}: { fetch: () => Promise<{ amount: string }> }
    ) => {
        return await fetch();
    }
);

export const fetchReferralSpendingClaim = createAsyncThunk(
    "referralRewards/fetchReferralSpendingClaim",
    async (
        {fetch}: { fetch: () => Promise<{ claim: ReferralSpendingsClaim | null }> }
    ) => {
        return await fetch();
    }
);

const slice = createSlice({
    name: "referralRewards",
    initialState,
    reducers: {
        setRewardsStatus(
            state,
            action: PayloadAction<{ ids: number[], status: ReferralRewardStatus }>
        ) {
            const {ids, status} = action.payload;
            for (const reward of state.rewards) {
                if (ids.includes(reward.id)) {
                    reward.status = status;
                }
            }
        },
        setReferralSpendingClaim(
            state,
            action: PayloadAction<{ claim: ReferralSpendingsClaim | null }>
        ) {
            state.referralSpendingClaim = action.payload.claim;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchReferralRewards.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(fetchReferralRewards.fulfilled, (state, action) => {
            state.status = "complete";
            state.rewards = action.payload.rewards;
            state.lockedRewardTiers = action.payload.lockedRewardTiers;
        });
        builder.addCase(fetchReferralRewards.rejected, (state) => {
            state.status = "error";
        });
        builder.addCase(fetchVillageLevels.fulfilled, (state, action) => {
            state.totalInvited = action.payload.totalInvited;
            state.reachedLevels = action.payload.reachedLevels;
        });
        builder.addCase(fetchNbReferrals.fulfilled, (state, action) => {
            state.nbReferrals = action.payload.totalInvited;
            state.nbEligible = action.payload.totalEligible;
        });
        builder.addCase(fetchClaimableAmount.fulfilled, (state, action) => {
            state.claimableAmount = action.payload.amount;
        });
        builder.addCase(fetchReferralSpendingClaim.fulfilled, (state, action) => {
            state.referralSpendingClaim = action.payload.claim;
        });
    }
});

export const selectClaimableReferralRewards = createSelector(
    (state: RootState) => state.referralRewards.rewards,
    (referrals: ReferralRewardWithTier[]) => referrals.filter(
        referral => referral.status === "new"
    ));
export const selectHasAnyReferralItems = createSelector(
    (state: RootState) => state.referralRewards.rewards,
    (state: RootState) => state.referralRewards.lockedRewardTiers,
    (rewards, lockedRewardsTiers) => {
        if (rewards.length > 0) {
            return true;
        }

        return lockedRewardsTiers.length > 0;
    }
);

export const selectClaimableAmount = createSelector(
    (state: RootState) => state.referralRewards.claimableAmount,
    (amount) => new BigNumber(amount)
);
export const {setRewardsStatus, setReferralSpendingClaim} = slice.actions;
export default slice.reducer;