import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {ErrorDialogActionType} from "../../common/types";
import {createSelector} from "reselect";
import {RootState} from "../store";

export type ErrorDialogType = "small" | "big";

type ActionType = {
  label: string;
  onClick: () => void;
};

// todo: refactor this to make it handle multiple errors
const initialState: {
  open: boolean;
  error: string;
  type?: ErrorDialogType;
  action?: ErrorDialogActionType;
  dismissable?: boolean;
} = {
  open: false,
  type: "small",
  error: "",
  action: undefined,
  dismissable: true,
};

const slice = createSlice({
  name: "errorDialog",
  initialState,
  reducers: {
    openErrorDialog: (
        state,
        action: PayloadAction<{
          error: string;
          type?: ErrorDialogType;
          action?: ErrorDialogActionType;
          dismissable?: boolean;
        }>
    ) => {
      state.open = true;
      state.type = action?.payload?.type || "small";
      state.error = action.payload.error;
      state.action = action.payload.action;
      state.dismissable = action.payload.dismissable ?? true;
    },
    closeErrorDialog: (state) => {
      state.open = false;
    },
  },
});

export const selectErrorDialog = createSelector(
    (state: RootState) => state.errorDialog,
    (errorDialog) => ({
      open: errorDialog.open,
      message: errorDialog.error,
      type: errorDialog.type,
      action: errorDialog.action,
      dismissable: errorDialog.dismissable
    })
);

export const {
  openErrorDialog,
  closeErrorDialog,
} = slice.actions;

export default slice.reducer;
