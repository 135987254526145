import {
    AssetType,
    GridAssetUpgradeState,
    IGridAsset,
    IPosition
} from "../../common/types/gameboard";
import {FederatedPointerEvent, IPointData, Point, Polygon, Sprite} from "pixi.js";
import {TILE_HEIGHT, TILE_WIDTH} from "../../utils/game";
import AssetShape from "../AssetShape";
import {AssetStatus, IToken, TokenPayload} from "../../common/types/actions";
import Manager from "../Manager";
import {AssetAttributes} from "@elrond-giants/game-db/types/entities";
import {defaultAssetAttributes} from "../../config/assets";

export default class GridAsset implements IGridAsset {
    readonly width: number // nb of tiles
    readonly height: number // nb of tiles
    readonly token: IToken | null;
    readonly type: AssetType;
    private _position!: IPosition; // grid start position
    private _allPositions: IPosition[] = [];
    status: AssetStatus;
    sprite: Sprite;
    protected _shape!: AssetShape;
    attributes: AssetAttributes;
    protected scale: number = 1;
    upgradeState: GridAssetUpgradeState = {
        startDate: null,
        finishDate: null,
        payment: null,
        instantFinishDate: null,
        instantFinishTaxPayment: null
    };

    constructor(
        sprite: Sprite,
        position: IPosition,
        width: number,
        height: number,
        token: IToken | null,
        type: AssetType,
        status: AssetStatus = "pending",
        attributes?: AssetAttributes,
        upgradeState?: GridAssetUpgradeState
    ) {

        this.sprite = sprite;
        this.width = width;
        this.height = height;
        this.position = position;
        this.token = token;
        this.type = type;
        this.status = status;
        this.attributes = attributes || defaultAssetAttributes;
        if (status === "upgrading" && !upgradeState) {
            throw new Error("Upgrade state is required for upgrading asset");
        }
        if (upgradeState) {
            this.upgradeState = upgradeState;
        }
        this.computeHitArea();
        this.initActions();
        this.setInteractive(true);
    }

    get screenWidth(): number {
        return TILE_WIDTH * this.scale * this.width;
    }

    get screenHeight(): number {
        return TILE_HEIGHT * this.scale * this.height;
    }

    set screenX(x:number){
        this.sprite.x = x;
    }

    get screenX():number {
        return this.sprite.x;
    }

    set screenY(y: number) {
        this.sprite.y = y;
    }

    get screenY(): number {
        return this.sprite.y;
    }

    get shape(): AssetShape {
        return this._shape;
    }

    set position(value: IPosition) {
        this._position = value;
        this.computeAllPositions();
    }

    get position(): IPosition {
        return this._position;
    }

    get allPositions(): IPosition[] {
        return this._allPositions;
    }

    public getCenterPoint(): IPointData {
        return {
            x: this.screenWidth / 2,
            y: this.screenHeight / 2
        };
    }

    public getGlobalCenterPosition(): IPosition {
        const tileCenter = this.getCenterPoint();
        const screenPosition = this.getGlobalPosition();
        return {
            x: tileCenter.x + screenPosition.x,
            y: tileCenter.y + screenPosition.y
        };
    }

    public getGlobalPosition(): IPosition {
        // return this.sprite.parent.toLocal(this.sprite.position);

        return this.sprite.getGlobalPosition();
    }

    public setScreenPosition(position: IPosition) {
        this.screenX = position.x;
        this.screenY = position.y;

        return this;
    }

    public clone(): GridAsset {
        const sprite = Sprite.from(this.sprite.texture);
        sprite.x = this.sprite.x;
        sprite.y = this.sprite.y;

        return new GridAsset(
            sprite,
            this._position,
            this.width,
            this.height,
            this.token,
            this.type,
            this.status,
            this.attributes,
            this.upgradeState
        );
    }


    public computeHitArea() {
        this._shape = new AssetShape([
            {
                x: this.screenWidth / 2,
                y: this.sprite.height - this.screenHeight
            },
            {
                x: this.screenWidth,
                y: this.sprite.height - (this.screenHeight / 2)
            },
            {
                x: this.screenWidth / 2,
                y: this.sprite.height
            },
            {
                x: 0,
                y: this.sprite.height - (this.screenHeight / 2)
            },
        ]);
        this.sprite.hitArea = this._shape.shape;
    }

    public setScale(scale: number) {
        this.scale = scale;
        this.computeHitArea();
    }

    public getScale(): number {
        return this.scale;
    }

    private computeAllPositions(): void {
        this._allPositions = [];
        for (let i = 0; i < this.height; i++) {
            for (let j = 0; j < this.width; j++) {
                const position = {
                    x: j + this.position.x,
                    y: i + this.position.y
                };
                this._allPositions.push(position);
            }
        }
    }

    public setInteractive(value: boolean) {
        this.sprite.eventMode = value ? "dynamic" : "none";

        return this;
    }

    protected initActions() {
        this.sprite.cursor = "pointer";
        this.sprite.on("pointertap", () => {
            Manager.getInstance().publishEvent("ASSET_CLICKED", {asset: this});
        });
    }
};