import {EventBus, EventHandler, Events} from "../common/types/events";

export default class EventsBus implements EventBus {
    private bus: Record<string, EventHandler[]> = {};

    subscribe(key: Events, handler: EventHandler): void {
        if (this.bus[key] === undefined) {
            this.bus[key] = [];
        }

        this.bus[key].push(handler);
    }

    unsubscribe(key: Events, handler: EventHandler): void {
        if (this.bus[key] === undefined) {return;}
        const idx = this.bus[key].indexOf(handler);
        if (idx < 0) {return;}
        this.bus[key].splice(idx, 1);
    }

    emit(key: Events, payload: any) {
        this.bus[key]?.forEach((handler) => {
            try {
                handler(payload);
            } catch (e) {
                // todo: handle errors
            }
        });
    }
};