import { configureStore } from "@reduxjs/toolkit";
import errorSlice from "./slices/errorSlice";
import inventorySlice from "./slices/inventorySlice";
import residentsSlice from "./slices/residentsSlice";
import walletAssetsSlice from "./slices/walletAssetsSlice";
import villageSlice from "./slices/villageSlice";
import transactionsSlice from "./slices/transactionsSlice";
import questsSlice from "./slices/questsSlice";
import popupManagementSlice from "./slices/popupManagementSlice";
import referralRewardsSlice from "./slices/referralRewardsSlice";
import shopSlice from "./slices/shopSlice";
import resourcesSlice from "./slices/resourcesSlice";
import leaderboardSlice from "./slices/leaderboardSlice";

const store = configureStore({
  reducer: {
    inventory: inventorySlice,
    walletAssets: walletAssetsSlice,
    residents: residentsSlice,
    errorDialog: errorSlice,
    village: villageSlice,
    transactions: transactionsSlice,
    quests: questsSlice,
    popupManagement: popupManagementSlice,
    referralRewards: referralRewardsSlice,
    shop: shopSlice,
    resources: resourcesSlice,
    leaderboard: leaderboardSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
