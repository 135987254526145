import {formatDistanceToNow, formatDistanceToNowStrict} from "date-fns";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function ucFirst(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const formatAddress = (address: string): string => {
  return address.substring(0, 4) + "..." + address.substring(address.length - 4);
}


export function formatCountdownText(
    {
        days,
        hours,
        minutes,
        seconds,
        full,
        separator
    }: {
        days: number,
        hours: number,
        minutes: number,
        seconds: number,
        full?: boolean,
        separator?: string
    }
) {
    const separatorText = separator || " ";
    const parts = [];
    if (days > 0) {
        parts.push(`${days}D`);
    }

    if (hours > 0 || days > 0) {
        parts.push(`${hours}H`);
    }

    if (full || parts.length < 2) {
        parts.push(`${minutes}M`);
    }
    if (full || parts.length < 2) {
        parts.push(`${seconds}S`);
    }

    return parts.join(separatorText);
}

export const formatUpgradeTime = (seconds: number): string => {
    return formatDistanceToNow(Date.now() + seconds * 1000);
}

export const formatAmount = (value: number, decimals: number = 1, lowDecimals: boolean = false): string => {
    if (value < 1000) {
        if (lowDecimals){
            return value.toFixed(decimals);
        }
        return value.toString();
    } else if (value < 1000000) {
        return `${(value / 1000).toFixed(decimals)}K`;
    } else if (value < 1000000000) {
        return `${(value / 1000000).toFixed(decimals)}M`;
    } else if (value < 1000000000000) {
        return `${(value / 1000000000).toFixed(decimals)}B`;
    }
    return `${(value / 1000000000000).toFixed(decimals)}T`;

}