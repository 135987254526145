import {Application} from "pixi.js";
import {IScene} from "../common/types/gameboard";
import Wallet from "../auth/Wallet";
import TxActionService from "../services/game-actions/TxActionService";
import {
    ActionCommitError, ActionCommitPending,
    ActionCommitSuccess, AssetEventPayload,
    GameActionKey,
} from "../common/types/actions";
import {EventBus, EventHandler, Events} from "../common/types/events";
import EventsBus from "../services/EventBus";
import ActionContext from "../services/contexts/ActionContext";
import {gameAction} from "../config/actions";

type ItemsLocker = {
    addLockedItems: (items: Record<string, number>) => void;
    removeLockedItems: (items: Record<string, number>) => void;
};

export default class Manager {
    private static instance: Manager;
    private app!: Application;
    private wallet: Wallet | undefined;
    private currentScene: IScene | undefined;
    private stage!: any;
    private _initialised = false;
    private _initialising = false;
    private actionService!: TxActionService;
    private eventBus!: EventBus;
    private itemsLocker!: ItemsLocker;

    private constructor() {}

    public static getInstance(): Manager {
        if (!Manager.instance) {
            Manager.instance = new Manager();
        }

        return Manager.instance;
    }

    get screen() {
        return this.app.screen;
    }

    get screenSize() {
        return {
            width: this.app.screen.width,
            height: this.app.screen.height,
        };
    }

    get stagePosition() {
        return this.stage.position;
    }

    get stageScale() {
        return this.stage.scale.x;
    }

    get initialised(): boolean {
        return this._initialised;
    }

    get initialising(): boolean {
        return this._initialising;
    }

    public async init(wallet: Wallet, actionService: TxActionService, itemsLocker: ItemsLocker) {
        this._initialising = true;
        this.wallet = wallet;
        this.actionService = actionService;
        this.itemsLocker = itemsLocker;
        this.eventBus = new EventsBus();
        this.app = new Application({
            view: document.getElementById("gameboard") as HTMLCanvasElement,
            resolution: window.devicePixelRatio || 1,
            autoDensity: true,
            resizeTo: document.getElementById("gameboard-container") as HTMLElement,
            backgroundColor: 0xFEF583
        });
        const {Viewport} = await import("pixi-viewport");
        this.stage = new Viewport(
            {
                screenWidth: this.app.screen.width,
                screenHeight: this.app.screen.height,
                events: this.app.renderer.events,
            }
        )
            .drag()
            .pinch()
            .wheel()
            .clampZoom({
                minScale: 0.5,
                maxScale: 2,
            })
            .on("drag-start", ({screen, viewport, world}) => {
                if (this.currentScene) {
                    this.currentScene.eventMode = "none";
                }
            })
            .on("drag-end", ({screen, viewport, world}) => {
                if (this.currentScene) {
                    this.currentScene.eventMode = "static";
                }
            })
            .on("zoomed-end", (viewport) => {
                this.currentScene?.onZoom({scale: viewport.scale});
            })
            .on("moved", ({viewport, type}) => {
                if (this.currentScene) {
                    this.currentScene.onCameraMove({
                        scale: viewport.scale,
                        position: viewport.position
                    });
                }
            });

        this.app.stage.addChild(this.stage);
        this.app.ticker.add(this.onTick, this);
        this._initialised = true;
        this._initialising = false;
    }

    public stop() {
        this.app.destroy(false, true);
    }

    public async changeScene(scene: IScene) {
        if (this.currentScene) {
            this.stage.removeChild(this.currentScene);
            this.currentScene.destroy();
        }

        await scene.init();
        this.currentScene = scene;
        this.stage.addChild(this.currentScene);

    }

    public onResize() {
        if (this.currentScene) {
            this.currentScene.onResize();
        }
    }

    public async commitAction<T extends GameActionKey>(actionContext: ActionContext<T>) {
        const usedItems = actionContext.getUsedItems();
        try {
            this.itemsLocker.addLockedItems(usedItems);
            const {tx, action, state, txHash, jobId} = await this.actionService.commitAction(
                actionContext,
                this.wallet!
            );
            /**
             * It the job id is present, it means that the action was queued for later execution,
             * and we'll emit a different event to handle this case.
             */
            if (jobId) {
                this.publishEvent(
                    "GAME_ACTION_COMMIT_PENDING",
                    {actionContext, jobId} satisfies ActionCommitPending<T>
                );
                return;
            }
            this.publishEvent(
                "GAME_ACTION_COMMIT_SUCCESS",
                {actionContext, action, state, tx, txHash} satisfies ActionCommitSuccess<T>
            );
            if (!actionContext.requiresTransaction) {
                const payload: AssetEventPayload = {
                    action: {
                        ...action!,
                        created_at: new Date(action!.created_at).toISOString(),
                    }
                };
                switch (actionContext.actionType) {
                    case gameAction.INIT_UPGRADE:
                        payload.assets = state as AssetEventPayload["assets"];
                        break;
                    case gameAction.ADD_GIANTS_TO_WORK:
                        payload.residents = state as AssetEventPayload["residents"];
                        break;
                }
                this.publishEvent(
                    "GAME_ACTION",
                    payload
                );
            }
        } catch (error) {
            this.itemsLocker.removeLockedItems(usedItems);
            this.publishEvent(
                "GAME_ACTION_COMMIT_ERROR",
                {actionContext, error} satisfies ActionCommitError<T>
            );
        }
    }

    public subscribeToAction(key: Events, handler: EventHandler) {
        this.eventBus.subscribe(key, handler);

        return this;
    }

    public unsubscribeFromAction(key: Events, handler: EventHandler) {
        this.eventBus.unsubscribe(key, handler);

        return this;
    }

    public publishEvent(key: Events, payload: any) {
        this.eventBus.emit(key, payload);
    }

    private onTick(delta: number) {
        if (this.currentScene) {
            this.currentScene.onTick(delta);
        }
    }
};
